import _ from 'underscore';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import {
  ArchivedState,
  PipelineState,
  SegmentId,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import { ActivityLog } from './LogActivityForm';

export const getProfileMissionId = (
  profile: SearchPoolProfile,
): string | null => {
  const currentSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
    isCurrent: true,
  });
  if (currentSequence?.linkedMissionId) {
    return currentSequence?.linkedMissionId;
  }
  if (!_.isEmpty(profile?.missionsInfo) && profile.missionsInfo?.length === 1) {
    return profile.missionsInfo?.[0].missionId;
  }
  return null;
};

export const getCurrentSegmentation = ({
  missionId,
  profile,
}: {
  missionId: string | null;
  profile: SearchPoolProfile;
}): SegmentId | null => {
  if (!missionId) return null;
  const missionData = _.find(
    profile?.missionsInfo || [],
    (mission) => mission.missionId === missionId,
  );
  if (!missionData || !missionData.data) return null;
  if (missionData.data.archived) return ArchivedState.ARCHIVED;
  return missionData.data?.segmentationStates?.[0].state as SegmentId;
};

export function getSubtype(log: ActivityLog): string {
  if (log.channel === 'note') {
    // notes should be submited as profile commment
    throw new Error(
      'ActivityLog for channel `note` can not be converted to activity item type',
    );
  }
  if (log.channel === 'meeting') {
    return 'meeting';
  }
  if (log.channel === 'call') {
    if (!log.caller) {
      return 'call';
    }
    const direction =
      log.caller === 'candidate' ? 'from-profile' : 'to-profile';
    return `call-${direction}`;
  }
  if (log.channel === 'vocal-message') {
    if (!log.caller) {
      return 'vocal-message';
    }
    const direction =
      log.caller === 'candidate' ? 'from-profile' : 'to-profile';
    return `vocal-message-${direction}`;
  }
  if (log.channel === 'texting-message') {
    if (!log.caller) {
      return 'texting-message';
    }
    const direction =
      log.caller === 'candidate' ? 'from-profile' : 'to-profile';
    return `texting-message-${direction}`;
  }
  if (log.channel === 'inspection-tour') {
    return 'inspection-tour';
  }
  const channel = { linkedin: 'linkedin-message', email: 'email' }[log.channel];
  const direction = { reply: 'from-profile', message: 'to-profile' }[
    log.type || 'message'
  ];
  return `${channel}-${direction}`;
}

export function getStageName(payload: ActivityLog): string | null {
  if (payload.shouldMoveToStageArchived) {
    return ArchivedState.ARCHIVED;
  }
  if (payload.type !== 'reply') {
    return null;
  }
  if (payload.shouldMoveToStagePositive) {
    return PipelineState.INTERESTED;
  }
  if (payload.shouldMoveToStageReplied) {
    return PipelineState.REPLIED;
  }
  return null;
}
