import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { Form, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { RadioButtonControl, RichEditorControl } from '@/components/FormFields';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import ProfilePreviewLoading from '@/routes/WatchCollectAllCandidates/components/ProfilePreviewLoading';
import RegisteredCheckbox from '@/components/RegisteredForm/RegisteredCheckbox';
import RegisteredDropdown from '@/components/RegisteredForm/RegisteredDropdown';
import { ARCHIVED_ENGAGEMENT } from '@/common';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import { ATS_NAMES_BY_TYPE } from '@/common/reveal';
import {
  connectorCanBeSynchronized,
  profileIsInATS,
} from '@/common/reveal/utils';
import { ISynchronisationSettings } from '@/graphql/fragments/RevealConnector';
import {
  ArchivedState,
  PipelineState,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import GenericButton from '@/components/Common/GenericButton';
import { ArchiveReason } from '@/graphql/archiveReasons';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import NowOrOtherDatePicker from '@/components/Common/NowOrOtherDatePicker';
import { getCurrentSegmentation, getProfileMissionId } from './helpers';

import './LogActivityForm.css';

type ActivityChannel =
  | 'note'
  | 'call'
  | 'email'
  | 'linkedin'
  | 'meeting'
  | 'texting-message'
  | 'vocal-message'
  | 'inspection-tour';
type ActivityDirection = 'message' | 'reply';
type ActivityAnswer = 'negative' | 'neutral' | 'positive';
const channels: ActivityChannel[] = [
  'linkedin',
  'note',
  'call',
  'texting-message',
  'vocal-message',
  'email',
  'meeting',
  'inspection-tour',
];
const TypeOptionsValues: ActivityDirection[] = ['reply', 'message'];
const TypeAnswerValues: ActivityAnswer[] = ['negative', 'neutral', 'positive'];

export type ActivityLog = {
  channel: ActivityChannel;
  type?: ActivityDirection | '';
  caller?: 'recruiter' | 'candidate';
  note?: string;
  date?: 'now' | Date;
  answerLabel?: ActivityAnswer | '';
  shouldInterruptSequence?: boolean;
  shouldMoveToStagePositive?: boolean;
  shouldMoveToStageReplied?: boolean;
  shouldMoveToStageArchived?: boolean;
  archivedEngagement?: string;
  synchronize?: boolean;
};

export type LogActivityFormProps = {
  clientId: string;
  onSubmit: (activityLog: ActivityLog) => void;
  handleClose?: () => void;
  submitLoading?: boolean;
  dense?: boolean;
  clientArchiveReasons: ArchiveReason[];
};

const SYNCHRONIZATION_OPTION_BY_CHANNEL: Record<
  ActivityChannel,
  keyof ISynchronisationSettings
> = {
  note: 'synchronizeNotes',
  call: 'synchronizeCalls',
  email: 'synchronizeEmails',
  linkedin: 'synchronizeLinkedinMessages',
  meeting: 'synchronizeNotes',
  'texting-message': 'synchronizeNotes',
  'vocal-message': 'synchronizeNotes',
  'inspection-tour': 'synchronizeNotes',
};

const LogActivityForm: React.FC<LogActivityFormProps> = (props) => {
  const {
    onSubmit,
    handleClose,
    clientId,
    submitLoading,
    dense,
    clientArchiveReasons,
  } = props;
  const { profile, loadingProfile } = useCandidateViewContext();
  const defaultConnector = useClientRevealConnector(clientId);
  const { synchronizationSettings } = defaultConnector || {};
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();
  const configurationParams = useMergedConfigurationParams();

  const shouldUseSimplifiedAgencyMode =
    configurationParams?.shouldDisplaySimplifiedAgencyActivityLog === 'true';

  const methods = useForm<ActivityLog>({
    defaultValues: shouldUseSimplifiedAgencyMode
      ? {
          channel: 'call',
          type: 'reply',
          answerLabel: 'neutral',
          note: '',
          caller: 'recruiter',
          shouldInterruptSequence: false,
          archivedEngagement: '',
          synchronize: false,
          shouldMoveToStageArchived: false,
          shouldMoveToStagePositive: false,
          shouldMoveToStageReplied: true,
        }
      : {
          channel: 'linkedin',
          type: 'reply',
          answerLabel: 'neutral',
          note: '',
          shouldInterruptSequence: true,
          shouldMoveToStagePositive: false,
          shouldMoveToStageReplied: true,
          shouldMoveToStageArchived: false,
          archivedEngagement: 'profile-not-interested',
          synchronize: !!synchronizationSettings?.synchronizeNotes,
        },
  });

  const { handleSubmit, watch, control, setValue } = methods;
  const channel = watch('channel');
  const answerLabel = watch('answerLabel');
  const type = watch('type');
  const shouldMoveToStageArchived = watch('shouldMoveToStageArchived');
  const shouldMoveToStageReplied = watch('shouldMoveToStageReplied');
  const shouldMoveToStagePositive = watch('shouldMoveToStagePositive');

  useEffect(() => {
    if (answerLabel === 'positive') {
      setValue('shouldMoveToStageArchived', false);
    } else {
      setValue('shouldMoveToStagePositive', false);
    }
  }, [answerLabel, setValue]);

  useEffect(() => {
    if (shouldMoveToStagePositive) {
      setValue('shouldMoveToStageArchived', false);
      setValue('shouldMoveToStageReplied', false);
    }
    // eslint-disable-next-line
  }, [shouldMoveToStagePositive]);

  useEffect(() => {
    if (shouldMoveToStageReplied && shouldMoveToStageArchived) {
      setValue('shouldMoveToStageArchived', false);
    }
    // eslint-disable-next-line
  }, [shouldMoveToStageReplied]);

  useEffect(() => {
    if (shouldMoveToStageArchived && shouldMoveToStageReplied) {
      setValue('shouldMoveToStageReplied', false);
    }
    // eslint-disable-next-line
  }, [shouldMoveToStageArchived]);

  const getDropdownOptions = useMemo(() => {
    const channelOptions = _.filter(
      channels.map((channelType) => ({
        value: channelType,
        label: t(`reveal.timeline.activityLog.channelOptions.${channelType}`),
      })),
      ({ value }) =>
        shouldUseSimplifiedAgencyMode
          ? [
              'call',
              'texting-message',
              'vocal-message',
              'meeting',
              'inspection-tour',
            ].includes(value)
          : ![
              'meeting',
              'texting-message',
              'vocal-message',
              'inspection-tour',
            ].includes(value),
    );
    const typeOptions = TypeOptionsValues.map((value) => ({
      value,
      label: t(`reveal.timeline.activityLog.typeOptions.${value}`),
    }));
    const answerOptions = TypeAnswerValues.map((value) => ({
      value,
      label: t(`reveal.timeline.activityLog.answer.${value}`),
    }));
    return {
      channelOptions,
      typeOptions,
      answerOptions,
    };
    // eslint-disable-next-line
  }, []);

  const archivedEngagementOptions = useMemo(() => {
    const options = ARCHIVED_ENGAGEMENT.map((value) => ({
      value,
      text: t(`reveal.pipelineSegmentations.archivedData.engagement.${value}`, {
        count: 1,
      }),
    }));
    if (!_.isEmpty(clientArchiveReasons)) {
      _.each(clientArchiveReasons, (customArchiveReason) => {
        options.push({
          value: customArchiveReason.id,
          text: customArchiveReason.title,
        });
      });
    }
    return options;
  }, [clientArchiveReasons, t]);

  const { channelOptions, typeOptions, answerOptions } = getDropdownOptions;

  const displayInterruptSequence =
    profile?.currentSequenceInfo?.sequenceId &&
    profile?.currentSequenceInfo?.state !== 'completed';

  const sequenceName = profile?.currentSequenceInfo?.sequence?.title;

  const isLinkedinOrEmailChannel =
    channel === 'linkedin' || channel === 'email';
  const missionId = getProfileMissionId(profile);
  const currentSegmentation = getCurrentSegmentation({ missionId, profile });

  if (loadingProfile) {
    return <ProfilePreviewLoading />;
  }

  const connectorType = defaultConnector?.type;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} className='log-activity-form'>
        <RadioButtonControl
          label={t('reveal.timeline.activityLog.fields.channel')}
          name='channel'
          options={channelOptions}
          defaultValue={shouldUseSimplifiedAgencyMode ? 'call' : 'linkedin'}
        />
        {isLinkedinOrEmailChannel && (
          <>
            <RadioButtonControl
              name='type'
              options={typeOptions}
              label={t('reveal.timeline.activityLog.typeOptions.label')}
              defaultValue={undefined}
            />
            {type === 'reply' && (
              <RadioButtonControl
                name='answerLabel'
                options={answerOptions}
                label={t('reveal.timeline.activityLog.answer.label')}
                defaultValue='neutral'
              />
            )}
          </>
        )}
        {shouldUseSimplifiedAgencyMode &&
          (channel === 'call' || channel === 'vocal-message') && (
            <RadioButtonControl
              name='caller'
              options={[
                {
                  value: 'recruiter',
                  label: 'Vous',
                },
                {
                  value: 'candidate',
                  label: 'Le candidat / intérimaire',
                },
              ]}
              label='Qui a appelé ?'
              defaultValue={undefined}
            />
          )}
        {shouldUseSimplifiedAgencyMode && channel === 'texting-message' && (
          <RadioButtonControl
            name='caller'
            options={[
              {
                value: 'recruiter',
                label: 'Vous',
              },
              {
                value: 'candidate',
                label: 'Le candidat / intérimaire',
              },
            ]}
            label='Qui a écrit ?'
            defaultValue={undefined}
          />
        )}
        <RichEditorControl
          clientId={clientId}
          profileId={profile?.id}
          label={
            <>
              {t('reveal.timeline.activityLog.fields.note')}{' '}
              <span className='control-label-supplement'>
                - {t('reveal.timeline.activityLog.fields.noteSupplement')}
              </span>
            </>
          }
          name='note'
          placeholder={t('reveal.timeline.activityLog.fields.notePlaceholder')}
        />
        {displayInterruptSequence && isLinkedinOrEmailChannel && (
          <div className='registered-checkbox'>
            <RegisteredCheckbox
              name='shouldInterruptSequence'
              label={
                <>
                  <span className='main-title'>{sequenceName} </span>
                  {t('reveal.timeline.activityLog.sequence.willBeStopped')}
                </>
              }
            />
          </div>
        )}
        {isLinkedinOrEmailChannel &&
          missionId &&
          currentSegmentation !== PipelineState.INTERESTED &&
          answerLabel === 'positive' && (
            <div className='registered-checkbox'>
              <RegisteredCheckbox
                name='shouldMoveToStagePositive'
                label={
                  <>
                    <span className='main-title'>
                      {t('reveal.timeline.activityLog.moveToStage.interested')}
                    </span>
                    <span className='subtitle'>
                      {t(
                        'reveal.timeline.activityLog.moveToStage.interestedSubtitle',
                      )}
                    </span>
                  </>
                }
              />
            </div>
          )}
        {isLinkedinOrEmailChannel &&
          missionId &&
          (currentSegmentation === PipelineState.PENDING ||
            currentSegmentation === PipelineState.IN_PROGRESS) &&
          type === 'reply' &&
          answerLabel !== 'positive' && (
            <div className='registered-checkbox'>
              <RegisteredCheckbox
                name='shouldMoveToStageReplied'
                label={
                  <span className='main-title'>
                    {t('reveal.timeline.activityLog.moveToStage.replied')}
                  </span>
                }
              />
            </div>
          )}
        {isLinkedinOrEmailChannel &&
          missionId &&
          currentSegmentation !== ArchivedState.ARCHIVED &&
          answerLabel !== 'positive' && (
            <div className='move-to-archived-wrapper '>
              <div className='registered-checkbox'>
                <RegisteredCheckbox
                  name='shouldMoveToStageArchived'
                  label={t('reveal.timeline.activityLog.moveToStage.archived')}
                />
              </div>
              {shouldMoveToStageArchived && (
                <>
                  <span className='subtitle'>{t('common.because')}</span>
                  <Form.Field className='dropdown-field'>
                    <RegisteredDropdown
                      name='archivedEngagement'
                      selection
                      options={archivedEngagementOptions}
                    />
                  </Form.Field>
                </>
              )}
            </div>
          )}

        {connectorType &&
          connectorCanBeSynchronized(connectorType) &&
          profileIsInATS(profile, connectorType) && (
            <div className='registered-checkbox'>
              <RegisteredCheckbox
                name='synchronize'
                defaultValue={
                  defaultConnector?.synchronizationSettings?.[
                    SYNCHRONIZATION_OPTION_BY_CHANNEL[channel]
                  ]
                }
                label={`${t('reveal.timeline.activityLog.synchronize')} ${
                  ATS_NAMES_BY_TYPE[connectorType]
                }`}
              />
            </div>
          )}

        {!dense && (
          <div className='submission-group'>
            {channel !== 'note' && !shouldUseSimplifiedAgencyMode && (
              <Controller
                name='date'
                control={control}
                defaultValue='now'
                render={({ field: { value, onChange } }) => (
                  <NowOrOtherDatePicker
                    value={value ?? 'now'}
                    onChange={onChange}
                  />
                )}
              />
            )}
            <GenericButton size='big' type='submit' disabled={submitLoading}>
              {t(
                `reveal.timeline.activityLog.${
                  isPlugin ? 'submitPlugin' : 'submit'
                }`,
              )}
            </GenericButton>
          </div>
        )}

        {dense && (
          <div className='submission-group dense'>
            <Button
              type='button'
              size='small'
              className='hsButton hsButtonTertiary'
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                handleClose && handleClose();
              }}
            >
              {t('reveal.profileEdition.cancel')}
            </Button>
            <Button primary type='submit' size='small' className='hsButton'>
              {type === 'reply'
                ? t('reveal.timeline.activityLog.submitReply')
                : t(
                    `reveal.timeline.activityLog.${
                      isPlugin ? 'submitPlugin' : 'submit'
                    }`,
                  )}
            </Button>
          </div>
        )}
      </Form>
    </FormProvider>
  );
};

export default LogActivityForm;
