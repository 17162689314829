import gql from 'graphql-tag';
import ContactData from './ContactData';
import ContactFlow from './ContactFlow';
import MinimalTask from './MinimalTask';
import MicroTask from './MicroTask';
import ProfileMissionInfoWithMission from './ProfileMissionInfoWithMission';
import ProfileProjectState from './ProfileProjectState';
import ProfileSequenceInfoWithSequence from './ProfileSequenceInfoWithSequence';
import CustomFieldsValues from './CustomFieldsValues';

export const TaskWithoutMessage = gql`
 fragment TaskWithoutMessage on Task {
    id
    ...MicroTask
    target {
      ... on RevealProfile {
        id       
      }
    }
  }
  ${MicroTask}
`;

export default gql`
  fragment Task on Task {
    id
    ...MinimalTask
    target {
      ... on RevealProfile {
        id
        currentSequenceInfo {
          ...ProfileSequenceInfoWithSequence
        }
        resumeData {
          firstname
          lastname
          photoLink
          headline {
            content {
              text
            }
          }
          mainEducation {
            schoolName
          }
          customFields {
            ...CustomFieldsValues
          }
        }
        missionsInfo {
          missionId
        }
        linkedProfilesGroup {
          id
          linkedProfileReferences {
            profileId
            metadata {
              key
              value
            }
          }
        }
      }
    }
  }
  ${MinimalTask}
  ${ProfileSequenceInfoWithSequence}
  ${CustomFieldsValues}
`;

export const TaskWithFullProfileContact = gql`
  fragment TaskWithFullProfileContact on Task {
    id
    ...MinimalTask
    target {
      ... on RevealProfile {
        id
        creationTimestamp
        lastATSRefreshTimestamp
        sent
        locked
        answerDetected
        email
        similarProfilesSearchString
        currentProject {
          ...ProfileProjectState
        }
        currentSequenceInfo {
          ...ProfileSequenceInfoWithSequence
        }
        missionsInfo {
          ...ProfileMissionInfoWithMission
        }
        contactFlow {
          ...ContactFlow
        }
        contactData {
          ...ContactData
        }
        explicitLastInteraction {
          author {
            firstname
            lastname
            email
          }
          date
        }
      }
    }
  }
  ${MinimalTask}
  ${ContactData}
  ${ContactFlow}
  ${ProfileProjectState}
  ${ProfileSequenceInfoWithSequence}
  ${ProfileMissionInfoWithMission}
`;
