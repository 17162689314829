import classNames from 'classnames';
import React, { PropsWithChildren, useContext } from 'react';

import DropdownControlsContext from '@/context/DropdownControlsContext';

import styles from './DropdownMenuItem.module.less';

interface DropdownMenuItemProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
}

const DropdownMenuItem = ({
  onClick,
  disabled,
  className,
  children,
}: PropsWithChildren<DropdownMenuItemProps>) => {
  const { closeDropdown } = useContext(DropdownControlsContext);

  return (
    <button
      type='button'
      className={classNames(styles.item, className)}
      disabled={disabled}
      onClick={(e) => {
        closeDropdown();
        onClick(e);
      }}
    >
      {children}
    </button>
  );
};

export default DropdownMenuItem;
