import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';

import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import GenericButton from '@/components/Common/GenericButton';

import styles from './SkipWithEmail.module.less';
import { SkipWithEmailContext } from '../SkipWithEmailContext/SkipWithEmailContext';

const SkipWithEmail = () => {
  const { t } = useTranslation();

  const {
    selectedReason,
    handleSkip,
    selectedTemplateId,
    setSelectedSenderId,
    emailPreviewOpen,
    setEmailPreviewOpen,
    skipWithEmail,
    setSkipWithEmail,
    setSendEmailModalOpen,
    selectedTemplate,
    selectedSender,
    emailBody,
    emailSubject,
    loading,
    templates,
    senders,
    skipLoading,
    onSelectTemplate,
    isNotInstantiated,
  } = React.useContext(SkipWithEmailContext);

  if (loading) {
    return null;
  }

  return (
    <>
      <div className={styles.divider} />
      <div className={styles.emailSkip}>
        <LabeledCheckbox
          checked={skipWithEmail}
          onClick={(e) => {
            e.stopPropagation();
            setSkipWithEmail(!skipWithEmail);
          }}
          label={t('reveal.applicantProfiles.skip.skipWithEmail')}
          disabled={!selectedReason}
        />
        {skipWithEmail && (
          <Popup
            hoverable
            on='hover'
            open={emailPreviewOpen}
            onOpen={() => setEmailPreviewOpen(true)}
            onClose={() => setEmailPreviewOpen(false)}
            size='huge'
            position='left center'
            trigger={
              <svg
                // onClick={(e) => e.stopPropagation()}
                className={styles.documentShow}
                width='20'
                height='20'
                viewBox='0 0 25 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12.5 4.5C7.5 4.5 3.23 7.61 1.5 12C3.23 16.39 7.5 19.5 12.5 19.5C17.5 19.5 21.77 16.39 23.5 12C21.77 7.61 17.5 4.5 12.5 4.5ZM12.5 17C9.74 17 7.5 14.76 7.5 12C7.5 9.24 9.74 7 12.5 7C15.26 7 17.5 9.24 17.5 12C17.5 14.76 15.26 17 12.5 17ZM12.5 9C10.84 9 9.5 10.34 9.5 12C9.5 13.66 10.84 15 12.5 15C14.16 15 15.5 13.66 15.5 12C15.5 10.34 14.16 9 12.5 9Z'
                  fill='black'
                  fillOpacity='0.3'
                />
              </svg>
            }
            popperModifiers={[
              {
                // Fix a positioning problem
                name: 'preventOverflow',
                options: {
                  boundariesElement: 'window',
                },
              },
            ]}
          >
            <Popup.Content>
              <div className={styles.emailPreview}>
                {!selectedTemplate ? (
                  <p>{t('reveal.applicantProfiles.skip.emptyTemplate')}</p>
                ) : (
                  <>
                    <div className={styles.emailSubject}>
                      <span className={styles.emailPreviewLabel}>
                        {t('reveal.applicantProfiles.skip.subject')}
                      </span>
                      <div className={styles.emailSubject}>{emailSubject}</div>
                    </div>
                    <div className={styles.emailContent}>
                      <span className={styles.emailPreviewLabel}>
                        {t('reveal.applicantProfiles.skip.body')}
                      </span>
                      <div className={styles.emailBody}>
                        <textarea
                          className={styles.emailBodyTextarea}
                          value={emailBody}
                          disabled
                          rows={5}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Popup.Content>
          </Popup>
        )}
        {skipWithEmail && selectedTemplate ? (
          <div className={styles.customizeEmail}>
            <span onClick={() => setSendEmailModalOpen(true)}>
              {t('reveal.applicantProfiles.skip.customizeEmail')}
            </span>
          </div>
        ) : (
          <div />
        )}
      </div>
      {skipWithEmail && (
        <div className={styles.emailSkipOptions}>
          <div className={styles.templateAndPreview}>
            <span>{t('reveal.applicantProfiles.skip.emailTemplate')}</span>
            <GenericDropdown
              contentClassName={styles.dropdownContent}
              trigger={
                <DropdownControlsContext.Consumer>
                  {({ toggleDropdown }) => (
                    <div className={styles.templateDropdown}>
                      <span
                        className={styles.templateSelectorTrigger}
                        role='button'
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown();
                        }}
                      >
                        {selectedTemplateId && selectedTemplate
                          ? selectedTemplate.title
                          : t('reveal.applicantProfiles.skip.chooseTemplate')}
                        <ArrowDown />
                      </span>
                    </div>
                  )}
                </DropdownControlsContext.Consumer>
              }
            >
              <DropdownMenuPanel
                options={_.map(templates, (template) => ({
                  id: template.id,
                  label: (
                    <span className={styles.clickable}>{template.title}</span>
                  ),
                }))}
                onSelect={(templateId, e) => {
                  e.stopPropagation();
                  onSelectTemplate(templateId);
                }}
              />
            </GenericDropdown>
          </div>
          <div className={styles.templateAndPreview}>
            <span>{t('reveal.applicantProfiles.skip.sender')}</span>
            <GenericDropdown
              contentClassName={styles.dropdownContent}
              trigger={
                <DropdownControlsContext.Consumer>
                  {({ toggleDropdown }) => (
                    <div className={styles.templateDropdown}>
                      <span
                        className={styles.templateSelectorTrigger}
                        role='button'
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown();
                        }}
                      >
                        {selectedSender
                          ? selectedSender.senderAddress
                          : t('reveal.applicantProfiles.skip.chooseSender')}
                        <ArrowDown />
                      </span>
                    </div>
                  )}
                </DropdownControlsContext.Consumer>
              }
            >
              <DropdownMenuPanel
                options={_.map(senders || [], (sender) => ({
                  id: sender.id,
                  label: (
                    <span className={styles.clickable}>
                      {sender.senderAddress}
                    </span>
                  ),
                }))}
                onSelect={(senderId, e) => {
                  e.stopPropagation();
                  setSelectedSenderId(senderId);
                }}
              />
            </GenericDropdown>
          </div>
        </div>
      )}
      <div className={styles.skipButtons}>
        {isNotInstantiated ? (
          <Popup
            on='hover'
            size='small'
            position='top center'
            trigger={
              <GenericButton
                onClick={() => {}}
                className={styles.disabledButton}
              >
                {t('reveal.applicantProfiles.skip.skip')}
              </GenericButton>
            }
          >
            <Popup.Content>
              {t('reveal.applicantProfiles.skip.formErrors.emptySnippets')}
            </Popup.Content>
          </Popup>
        ) : (
          <GenericButton
            onClick={() => {
              handleSkip();
            }}
            disabled={
              skipLoading ||
              !selectedReason ||
              (skipWithEmail &&
                (!selectedTemplate ||
                  !selectedSender ||
                  !emailBody ||
                  !emailSubject))
            }
          >
            {t('reveal.applicantProfiles.skip.skip')}
          </GenericButton>
        )}
      </div>
    </>
  );
};

export default SkipWithEmail;
