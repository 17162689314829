import React, { useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import GenericDropdown from '@/components/Common/GenericDropdown';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownControlsContext from '@/context/DropdownControlsContext';

import styles from './MissionSelect.module.less';

type MissionSelectProps = {
  onChange?: (value?: string) => void;
  missionId?: string;
};

const MissionSelect = ({ onChange, missionId }: MissionSelectProps) => {
  const { t } = useTranslation();
  const { jobOptions } = useSearchPoolJobOptions('reveal', {
    activeOnly: true,
  });

  const missionOptionMap = useMemo(() => {
    const result = {} as Record<string, { label: string; id: string }>;
    _.each(jobOptions, ({ id, data }) => {
      result[id] = {
        id,
        label: data?.title || id,
      };
    });
    return result;
  }, [jobOptions]);

  return (
    <GenericDropdown
      className={styles.dropdown}
      contentClassName={styles.dropdownContent}
      trigger={
        <DropdownControlsContext.Consumer>
          {({ toggleDropdown }) => (
            <span
              className={classNames(styles.clickable, styles.trigger)}
              role='button'
              onClick={toggleDropdown}
              onKeyPress={toggleDropdown}
            >
              {missionId
                ? missionOptionMap[missionId]?.label
                : t('common.none')}
              <ArrowDown />
            </span>
          )}
        </DropdownControlsContext.Consumer>
      }
    >
      <DropdownMenuPanel
        options={_.values(missionOptionMap || [])}
        onSelect={(senderId) => {
          if (onChange) {
            onChange(senderId);
          }
        }}
      />
    </GenericDropdown>
  );
};

export default MissionSelect;
