export function getDefaultJobPosting(clientId: string) {
  if (clientId.includes('humanskills')) {
    return threePartJobPostingTemplate;
  }
  if (clientId.includes('sixdegres')) {
    return threePartJobPostingTemplate;
  }
  if (clientId.includes('bloomays')) {
    return threePartJobPostingTemplate;
  }
  return defaultJobPosting;
}

const defaultJobPosting: JobPostingType = {
  title: { default: '' || '', fr: '', en: '' },
  description: {
    type: 'multi-sections',
    sections: [
      {
        type: 'simple-section',
        title: { default: '' },
        content: { default: '' },
        __typename: 'SimpleJobPostingDescriptionSection',
      },
    ],
    __typename: 'MultiSectionsJobPostingDescription',
  },
  locations: [
    {
      identifier: 'paris',
      label: {
        default: 'Paris',
      },
    },
  ],
  salary: {
    min: 20000,
    max: 40000,
    currency: 'EUR',
    type: 'period-range',
    period: 'year',
  },
  remote: {
    identifier: 'full-time',
    label: {
      default: 'Télétravail complet accepté',
    },
  },
  contractType: {
    identifier: 'cdi',
    label: {
      default: 'CDI',
    },
  },
  foldering: {},
  application: {
    form: {
      type: 'contact-and-extra-questions',
      __typename: 'ContactAndExtraQuestionsJobPostingApplicationForm',
      extraQuestionsForm: {
        type: 'connect-form',
      },
    },
  },
  published: false,
};

const threePartJobPostingTemplate: JobPostingType = {
  ...defaultJobPosting,
  description: {
    type: 'multi-sections',
    sections: [
      {
        type: 'simple-section',
        title: {
          default: "Description de l'entreprise",
          en: "Description de l'entreprise",
          fr: "Description de l'entreprise",
        },
        content: { default: '' },
        __typename: 'SimpleJobPostingDescriptionSection',
      },
      {
        type: 'simple-section',
        title: {
          default: 'Description du poste',
          en: 'Description du poste',
          fr: 'Description du poste',
        },
        content: { default: '' },
        __typename: 'SimpleJobPostingDescriptionSection',
      },
      {
        type: 'simple-section',
        title: {
          default: 'Profil recherché',
          en: 'Profil recherché',
          fr: 'Profil recherché',
        },
        content: { default: '' },
        __typename: 'SimpleJobPostingDescriptionSection',
      },
    ],
    __typename: 'MultiSectionsJobPostingDescription',
  },
};

export type JobPostingType = {
  title: {
    default: string;
    fr?: string;
    en?: string;
  };
  description: {
    type: string;
    sections: JobPostingDescriptionSection[];
    __typename?: string;
  };
  locations?: {
    identifier: string;
    label: {
      default: string;
    };
  }[];
  salary?: {
    min?: number;
    max?: number;
    currency: string;
    type: string;
    period?: string;
    freeText?: string;
  };
  remote?: {
    identifier: string;
    label: {
      default: string;
    };
  };
  contractType?: {
    identifier: string;
    label: {
      default: string;
    };
  };
  foldering?: any;
  application?: {
    form: {
      type: string;
      extraQuestionsForm: {
        type: string;
        id?: string;
      };
      __typename?: string;
    };
  };
  published: boolean;
};

export type JobPostingDescriptionSection = {
  type: string;
  title?: {
    default: string;
    fr?: string;
    en?: string;
  };
  content?: {
    default: string;
    fr?: string;
    en?: string;
  };
  file?: {
    content: string;
    url?: string;
    name?: string;
  };
  url?: string;
  name?: string;
  __typename?: string;
};
