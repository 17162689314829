import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import { Trans, useTranslation } from 'react-i18next';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import { CalibrationAndRecommendationIcon } from '@/assets/icons';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

const RevealSkippedFromMissionApplicationsItem = ({
  item,
  unbiasedModeEnabled,
  profile,
}) => {
  const { t } = useTranslation();

  const [job, setJob] = useState(null);
  const { action } = item || {};
  const { firstname, lastname } = profile?.resumeData || {};
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;
  const { jobOptions, loading } = useSearchPoolJobOptions('reveal');

  useEffect(() => {
    if (!loading) {
      setJob(
        _.findWhere(jobOptions, {
          id: action?.missionId,
        }),
      );
    }
  }, [loading, action?.missionId, jobOptions]);

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor:
                  TASK_ICONS['skipped-from-mission-recommendations'].color,
              }}
              className='icon'
            >
              <CalibrationAndRecommendationIcon />
            </div>
          </div>
          <div className='author'>
            <div>
              <Trans
                i18nKey='profile.contact.timeline.skippedFromMissionApplications.description'
                values={{
                  profileName,
                  missionName: job?.data?.title,
                }}
                components={{
                  bold: <b />,
                  lightBold: <b className='light-bold' />,
                }}
              />
            </div>

            <AuthorAndDate timelineItem={item} />
          </div>
        </div>
        <div className='card-content comment'>
          <div className='body'>
            {t('profile.contact.timeline.reasons')}
            <ul>
              {_.map(
                _.compact(
                  getReasonTextsFromRawReason({
                    rawReason: action?.reason || '',
                    t,
                  }),
                ),
                (reason) => (
                  <li key={reason}>{reason}</li>
                ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkippedFromMissionApplicationsItem = ({
  profile,
  timelineItem,
  updateProfileComment,
  updateSearchPoolProfileComment,
  clientId,
}) => {
  const author = timelineItem?.author ? timelineItem?.author : {};

  return (
    <RevealSkippedFromMissionApplicationsItem
      profile={profile}
      item={timelineItem}
      author={author}
      updateProfileComment={updateProfileComment}
      updateSearchPoolProfileComment={updateSearchPoolProfileComment}
      clientId={clientId}
    />
  );
};

const getReasonTextsFromRawReason = ({ rawReason, t }) => {
  return _.compact(
    _.flatten(
      _.map((rawReason || '').split(';'), (subpart) =>
        _.map(subpart.split('#'), (part, index) =>
          index === 0 && part
            ? t(`profile.steps.newSkip.reasons.${part}`)
            : part,
        ),
      ),
    ),
  );
};

export default SkippedFromMissionApplicationsItem;
