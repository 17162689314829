import React, { ReactNode } from 'react';

import ButtonDropdown from '../ButtonDropdown';
import DropdownMenuPanel, { DropdownMenuOption } from '../DropdownMenuPanel';

import {
  GenericButtonSize,
  GenericButtonPrimacy,
  GenericButtonColor,
} from '../GenericButton';
import { GenericDropdownPosition } from '../GenericDropdown';

type Props = {
  title?: ReactNode;
  options: DropdownMenuOption[];
  onSelect: (id: string) => void;
  primacy?: GenericButtonPrimacy;
  color?: GenericButtonColor;
  size?: GenericButtonSize;
  position?: GenericDropdownPosition;
  isIcon?: boolean;
  disabled?: boolean;
  className?: string;
  upward?: boolean;
};

const ButtonDropdownMenu = ({ options, onSelect, ...rest }: Props) => (
  <ButtonDropdown {...rest}>
    <DropdownMenuPanel options={options} onSelect={onSelect} />
  </ButtonDropdown>
);

export default ButtonDropdownMenu;
