import React, {
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
  MouseEvent,
  FC,
} from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  ArchivedState,
  PipelineState,
  Segment,
  SegmentId,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import useClickOutside from '@/hooks/common/useClickOutside';
import {
  ARCHIVED_ENGAGEMENT,
  ARCHIVED_REASONS,
  REPLIED_ANSWERS,
} from '@/common';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import { ArchiveReason, GET_ARCHIVE_REASONS } from '@/graphql/archiveReasons';
import useInterestedSubPipeline from '@/graphql/hooks/searchPoolJob/useInterestedSubPipeline';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import styles from './MoveToStageAction.module.less';
import GenericButton from '../Common/GenericButton';

const STAGES_MOVED_DIRECTLY: SegmentId[] = [
  PipelineState.PENDING,
  PipelineState.IN_PROGRESS,
];

interface Props {
  missionId?: string;
  currentStage?: string;
  stages: Segment[];
  candidates: SearchPoolProfile[];
  onMove: (
    stage: string,
    prevStageId: string,
    labels?: string[],
    clientArchiveReasonId?: string,
    interestedStepId?: string,
  ) => void;
  isSubmitting: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  absolutePosition: string;
  clientId: string;
}

const MoveToStageAction: React.FC<Props> = ({
  missionId = '',
  currentStage,
  stages,
  onMove,
  isSubmitting,
  candidates,
  setOpen,
  absolutePosition,
  clientId,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [selectedStage, setSelectedStage] = useState({ id: '', name: '' });
  const [repliedAnswer, setRepliedAnswer] = useState('neutral');
  const [archivedEngagement, setArchivedEngagement] = useState('');
  const [archivedReason, setArchivedReason] = useState('');
  const [clientArchiveReasonId, setClientArchiveReasonId] = useState('');

  const { interestedSubPipeline } = useInterestedSubPipeline({ missionId });
  const interestedSteps = interestedSubPipeline?.steps;

  const { data: archiveReasonsData } = useQuery(GET_ARCHIVE_REASONS, {
    variables: { clientId },
    fetchPolicy: 'network-only',
  });

  const clientArchiveReasons: ArchiveReason[] =
    archiveReasonsData?.client?.archiveReasons;

  const isPlugin = useIsPlugin();

  /**
   * Use to reset data in modal when custom modal closes
   */
  useClickOutside(ref, (event: MouseEvent) => {
    if (!ref.current) return;
    const target = event.target as Element;
    if (!target?.classList) return;
    const { classList } = target;
    if (
      classList?.contains('reveal-stage') ||
      classList?.contains('reveal-stage-header-selected') ||
      classList?.contains('reveal-stage-action') ||
      classList?.contains('reveal-stage-prev') ||
      (classList?.contains('dropdown') && classList?.contains('icon'))
    ) {
      return;
    }
    setOpen(false);
  });

  if (_.isEmpty(candidates)) {
    return null;
  }

  const moveStage = (stageId: string) => {
    let labels: string[] = [];
    if (stageId === PipelineState.REPLIED) {
      labels = [repliedAnswer === 'neutral' ? 'medium' : repliedAnswer];
    }
    if (stageId === ArchivedState.ARCHIVED) {
      labels = [archivedEngagement, archivedReason];
    }
    onMove(
      stageId,
      currentStage || 'pending',
      _.compact(labels),
      clientArchiveReasonId,
    );
  };

  const stageIsDisabled = ({ id }: Segment) => {
    if (currentStage !== id || id === ArchivedState.ARCHIVED) {
      return false;
    }
    if (currentStage === 'interested' && !_.isEmpty(interestedSteps)) {
      return false;
    }
    return true;
  };

  const onSelectStage = (stage: Segment) => {
    const { id, name } = stage;
    if (stageIsDisabled(stage)) {
      return;
    }
    if (STAGES_MOVED_DIRECTLY.includes(id)) {
      moveStage(id);
    } else {
      setSelectedStage({ id, name: name || '' });
    }
  };

  const onUnselectStage = () => {
    setSelectedStage({ id: '', name: '' });
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        absolutePosition === 'left' || isPlugin
          ? styles.absoluteLeft
          : styles.absoluteRight,
        isPlugin && styles.pluginWrapper,
      )}
      ref={ref}
    >
      {!selectedStage.id ? (
        <>
          <div className={styles.header}>
            {t('reveal.pipelineSegmentations.move')}{' '}
            {candidates.length > 1
              ? candidates.length
              : `${candidates[0]?.resumeData?.firstname || ''} ${
                  candidates[0]?.resumeData?.lastname || ''
                }`}{' '}
            {candidates.length > 1 && (
              <>
                {t('reveal.pipelineSegmentations.contacts', {
                  count: candidates.length,
                })}{' '}
              </>
            )}
            {t('reveal.pipelineSegmentations.to')}
          </div>
          <div>
            {_.map(stages, (stage) => (
              <div
                key={stage.id}
                className={classNames(
                  styles.stage,
                  'reveal-stage',
                  stageIsDisabled(stage) && styles.stageDisabled,
                )}
                onClick={() => onSelectStage(stage)}
                aria-hidden='true'
              >
                {t(`reveal.pipelineSegmentations.${stage.id}`)}
                {STAGES_MOVED_DIRECTLY.includes(stage.id) ? (
                  <GenericButton disabled={isSubmitting}>
                    {t('reveal.pipelineSegmentations.move')}
                  </GenericButton>
                ) : (
                  <i className='ri-arrow-right-s-line reveal-stage-action' />
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className={styles.header}>
            <div
              className={`${styles.headerStageSelected} reveal-stage-header-selected`}
              onClick={onUnselectStage}
              aria-hidden='true'
            >
              <i className='ri-arrow-left-s-line reveal-stage-prev' />
              {t(`reveal.pipelineSegmentations.${selectedStage.id}`)}
            </div>
            {(selectedStage.id !== PipelineState.INTERESTED ||
              _.isEmpty(interestedSteps)) && (
              <GenericButton
                disabled={isSubmitting}
                onClick={() => moveStage(selectedStage.id)}
              >
                {t('reveal.pipelineSegmentations.move')}
              </GenericButton>
            )}
          </div>
          {selectedStage.id === PipelineState.INTERESTED &&
            interestedSteps !== undefined && (
              <InterestedStageContent
                interestedSteps={interestedSteps}
                moveToInterestedSubstep={(interestedStepId) =>
                  onMove(
                    'interested',
                    currentStage || 'pending',
                    undefined,
                    undefined,
                    interestedStepId,
                  )
                }
                isSubmitting={isSubmitting}
              />
            )}
          <div className={styles.content}>
            {selectedStage.id === PipelineState.REPLIED && (
              <RepliedStageContent
                repliedAnswer={repliedAnswer}
                setRepliedAnswer={setRepliedAnswer}
                moveStage={moveStage}
                candidatesLength={candidates.length}
              />
            )}
            {selectedStage.id === ArchivedState.ARCHIVED && (
              <ArchivedStageContent
                archivedEngagement={archivedEngagement}
                setArchivedEngagement={setArchivedEngagement}
                archivedReason={archivedReason}
                setArchivedReason={setArchivedReason}
                candidatesLength={candidates.length}
                clientArchiveReasonId={clientArchiveReasonId}
                setClientArchiveReasonId={setClientArchiveReasonId}
                clientArchiveReasons={clientArchiveReasons}
              />
            )}
            <SequenceDisplayed candidates={candidates} />
          </div>
        </>
      )}
    </div>
  );
};

interface InterestedProps {
  interestedSteps: { id: string; title: string }[];
  moveToInterestedSubstep: (id: string) => void;
  isSubmitting: boolean;
}

const InterestedStageContent: FC<InterestedProps> = ({
  interestedSteps,
  moveToInterestedSubstep,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {_.map(interestedSteps, ({ id, title }) => (
        <div
          key={id}
          className={classNames(styles.stage, 'reveal-stage')}
          onClick={() => moveToInterestedSubstep(id)}
        >
          {title}
          <GenericButton disabled={isSubmitting}>
            {t('reveal.pipelineSegmentations.move')}
          </GenericButton>
        </div>
      ))}
    </>
  );
};

interface RepliedProps {
  repliedAnswer: string;
  setRepliedAnswer: Dispatch<SetStateAction<string>>;
  moveStage: (stageId: string) => void;
  candidatesLength: number;
}

export const RepliedStageContent: React.FC<RepliedProps> = ({
  repliedAnswer,
  setRepliedAnswer,
  moveStage,
  candidatesLength,
}) => {
  const { t } = useTranslation();
  const onClick = (answer: string) => {
    setRepliedAnswer(answer);
  };

  return (
    <>
      <span className={styles.paragraph}>
        {t('reveal.pipelineSegmentations.repliedData.text')}{' '}
      </span>
      <div className={styles.buttons}>
        {_.map(REPLIED_ANSWERS, (answer, index) => (
          <button
            type='button'
            key={index}
            className={classNames(
              styles.customButton,
              repliedAnswer === answer && styles.buttonActive,
            )}
            onClick={() => onClick(answer)}
          >
            {t(`reveal.pipelineSegmentations.repliedData.${answer}`)}
          </button>
        ))}
      </div>

      {repliedAnswer === 'positive' && (
        <div className={styles.blueBox}>
          <span>
            {t('reveal.pipelineSegmentations.repliedData.positiveWarning', {
              count: candidatesLength,
            })}
          </span>
          <GenericButton onClick={() => moveStage(PipelineState.INTERESTED)}>
            {t('reveal.pipelineSegmentations.repliedData.moveToInterested')}
          </GenericButton>
        </div>
      )}
    </>
  );
};

interface ArchivedProps {
  archivedEngagement: string;
  setArchivedEngagement: Dispatch<SetStateAction<string>>;
  archivedReason: string;
  setArchivedReason: Dispatch<SetStateAction<string>>;
  clientArchiveReasonId: string;
  setClientArchiveReasonId: Dispatch<SetStateAction<string>>;
  candidatesLength: number;
  clientArchiveReasons: ArchiveReason[];
}

export const ArchivedStageContent: React.FC<ArchivedProps> = ({
  archivedEngagement,
  setArchivedEngagement,
  archivedReason,
  setArchivedReason,
  clientArchiveReasonId,
  setClientArchiveReasonId,
  candidatesLength,
  clientArchiveReasons,
}) => {
  const { t } = useTranslation();
  const configurationParams = useMergedConfigurationParams();

  const onClickEngagement = (answer: string) => {
    if (!_.isEmpty(clientArchiveReasonId)) {
      setClientArchiveReasonId('');
    }
    if (archivedEngagement === answer) {
      setArchivedEngagement('');
    } else {
      setArchivedEngagement(answer);
    }
  };

  const onClickClientArchiveReason = (customReason: string) => {
    if (!_.isEmpty(archivedEngagement)) {
      setArchivedEngagement('');
    }
    if (clientArchiveReasonId === customReason) {
      setClientArchiveReasonId('');
    } else {
      setClientArchiveReasonId(customReason);
    }
  };

  const onClickReason = (answer: string) => {
    setArchivedReason(answer);
  };

  useEffect(() => {
    if (archivedEngagement !== 'profile-interested' && archivedReason !== '') {
      setArchivedReason('');
    }
  }, [archivedEngagement, archivedReason, setArchivedReason]);

  return (
    <>
      {configurationParams?.shouldHideNativeArchiveReasons !== 'true' && (
        <div style={{ marginBottom: '20px' }}>
          <span className={styles.paragraph}>
            {t('reveal.pipelineSegmentations.archivedData.engagement.label')}
          </span>
          <div className={styles.buttons}>
            {_.map(ARCHIVED_ENGAGEMENT, (answer, index) => (
              <button
                type='button'
                key={index}
                className={classNames(
                  styles.customButton,
                  archivedEngagement === answer && styles.buttonActive,
                )}
                onClick={() => onClickEngagement(answer)}
              >
                {t(
                  `reveal.pipelineSegmentations.archivedData.engagement.${answer}`,
                  { count: candidatesLength },
                )}
              </button>
            ))}
          </div>
          {archivedEngagement === 'profile-interested' && (
            <div className={styles.paddingY}>
              <span className={styles.paragraphLight}>
                {t(
                  'reveal.pipelineSegmentations.archivedData.archiveReason.label',
                )}
              </span>
              <div className={styles.buttons}>
                {_.map(ARCHIVED_REASONS, (answer, index) => (
                  <button
                    type='button'
                    key={index}
                    className={classNames(
                      styles.customButton,
                      archivedReason === answer && styles.buttonActive,
                    )}
                    onClick={() => onClickReason(answer)}
                  >
                    {t(
                      `reveal.pipelineSegmentations.archivedData.archiveReason.${answer}`,
                      { count: candidatesLength },
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <span className={styles.paragraph} style={{ display: 'block' }}>
        {t('reveal.pipelineSegmentations.archivedData.customReasons')}
      </span>
      <div className={styles.buttons}>
        {_.map(clientArchiveReasons, (archiveReason, index) => (
          <button
            type='button'
            key={index}
            className={classNames(
              styles.customButton,
              clientArchiveReasonId === archiveReason.id && styles.buttonActive,
            )}
            onClick={() => onClickClientArchiveReason(archiveReason.id)}
          >
            {archiveReason.title}
          </button>
        ))}
      </div>
    </>
  );
};

const SequenceDisplayed = ({
  candidates,
}: {
  candidates: SearchPoolProfile[];
}) => {
  const { t } = useTranslation();
  const hasSequenceToDisplay = () =>
    candidates.some((candidate) => !!candidate.currentSequenceInfo);

  const sequenceToDisplay = useMemo((): string => {
    if (!hasSequenceToDisplay()) return 'all';
    let sequence = '';
    candidates.forEach((candidate) => {
      const title = candidate.currentSequenceInfo?.sequence?.title;
      if (!title) return;
      if (!sequence) {
        sequence = title;
      }
      if (sequence !== title) {
        sequence = 'all';
      }
    });
    return sequence;
    // eslint-disable-next-line
  }, [candidates]);

  return (
    <p className={styles.sequence}>
      <span
        className={classNames(
          sequenceToDisplay !== 'all' && styles.sequenceName,
        )}
      >
        {sequenceToDisplay !== 'all'
          ? sequenceToDisplay
          : t('reveal.pipelineSegmentations.allSequences')}
      </span>{' '}
      {t('reveal.pipelineSegmentations.sequenceStopped', {
        count: sequenceToDisplay === 'all' ? 2 : 1,
      })}
    </p>
  );
};

export default MoveToStageAction;
