import gql from 'graphql-tag';

export interface IClientPermissions {
  watchCollect: boolean;
  watchCollectV2: boolean;
  skipWithoutReason: boolean;
  skipWithoutModal: boolean;
  canEditUserDescription: boolean;
  integrations: boolean;
  secondFollowup: boolean;
  analytics: boolean;
  maxActiveOffersNumber: number;
  maxNbFollowups: number;
  termsOfService: boolean;
  csvImportAndExport: boolean;
  profileEvaluation: boolean;
  canEvaluatePendingProfiles: boolean;
  careerPage: boolean;
  reveal: boolean;
  revealAnalytics: boolean;
  hideEndorsements: boolean;
  crmMarketing: boolean;
  crmPages: boolean;
  advancedTemplating: boolean;
  diversityAnalytics: boolean;
  dataProviderIntegrations: boolean;
  marketplaceSplitPending: boolean;
  marketplaceUnifiedView: boolean;
  aiSequenceGeneration: boolean;
  aiSequenceGenerationSidebar: boolean;
  canAskEmailEnrichmentCredits?: boolean;
  defaultEnrichmentMonthlyAmountGivenPerUser?: number;
  maxEnrichmentMonthlyAmountGivenForClient?: number;
  advancedMergeTags?: boolean;
  allUsersCanEditRevealAtsConfiguration?: boolean;
  allUsersCanEditCustomArchiveReasons?: boolean;
  nextAvailability?: boolean;
  customPipelines?: boolean;
  canEditAutomationIntegrations?: boolean;
  canEditCustomTasks?: boolean;
  canHandleCompanyContacts?: boolean;
  canEditHumanContactSubtypes?: boolean;
  canFilterProjectProfiles: boolean;
  salesProjects: boolean;
  crmOverview?: boolean;
  salaryBenchmark: boolean;
  hardFilters: boolean;
  unipile: boolean;
  whatsappSequenceActions: boolean;
  aiTokenGeneration: boolean;
  aiTokenExperimental: boolean;
  aiRecoBlurb: boolean;
  limitedAccessToDepartments?: boolean;
  projectDynamicImports?: boolean;
  aiSearchFromText?: boolean;
  transformedResume?: boolean;
  inbox?: boolean;
  sharedNotes?: boolean;
  positionDescription?: boolean;
  companiesDealStates?: boolean;
  companiesOverview?: boolean;
  canScheduleAtBulkEnrollment?: boolean;
  canBulkInstantiateAiMergeTags?: boolean;
  searchLocationRadius?: boolean;
  documentGeneration?: boolean;
  askCompanyAtProjectCreation?: boolean;
  profileDocumentsTab?: boolean;
  projectDocuments?: boolean;
  documentTypes?: boolean;
  canMergeProfiles?: boolean;
  canSearchNameOnLinkedIn?: boolean;
  canUsePeriodicCheckInvite?: boolean;
  canSendApplicationRejectionEmail?: boolean;
}

export default /* GraphQL */ gql`
  fragment ClientPermissions on Permissions {
    watchCollect
    watchCollectV2
    skipWithoutReason
    skipWithoutModal
    canEditUserDescription
    integrations
    secondFollowup
    analytics
    maxActiveOffersNumber
    maxNbFollowups
    termsOfService
    csvImportAndExport
    profileEvaluation
    canEvaluatePendingProfiles
    careerPage
    reveal
    revealAnalytics
    hideEndorsements
    crmMarketing
    crmPages
    advancedTemplating
    advancedMergeTags
    diversityAnalytics
    dataProviderIntegrations
    marketplaceSplitPending
    marketplaceUnifiedView
    aiSequenceGeneration
    aiSequenceGenerationSidebar
    allUsersCanEditRevealAtsConfiguration
    allUsersCanEditCustomArchiveReasons
    # Enrichment permissions
    canAskEmailEnrichmentCredits
    nextAvailability
    customPipelines
    canEditAutomationIntegrations
    canEditCustomTasks
    canHandleCompanyContacts
    canEditHumanContactSubtypes
    canFilterProjectProfiles
    salesProjects
    crmOverview
    salaryBenchmark
    hardFilters
    unipile
    whatsappSequenceActions
    aiTokenGeneration
    aiTokenExperimental
    aiRecoBlurb
    limitedAccessToDepartments
    projectDynamicImports
    aiSearchFromText
    transformedResume
    inbox
    sharedNotes
    positionDescription
    companiesDealStates
    companiesOverview
    canScheduleAtBulkEnrollment
    canBulkInstantiateAiMergeTags
    searchLocationRadius
    documentGeneration
    askCompanyAtProjectCreation
    profileDocumentsTab
    projectDocuments
    documentTypes
    canMergeProfiles
    canSearchNameOnLinkedIn
    canUsePeriodicCheckInvite
    canSendApplicationRejectionEmail
  }
`;
