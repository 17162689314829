import getExternalExpressionEvaluatorFromType from '@/common/mergeTags/externalEvaluators';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import useMergeTagsProfileContext from '@/common/mergeTags/useMergeTagsProfileContext';
import { getHtmlStringAsText } from '@/common/utils/htmlToText';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import { GET_CLIENT_TEMPLATES } from '@/graphql/clientTemplates';
import { BaseMailTemplateFragment } from '@/graphql/fragments/BaseMailTemplate';
import { TextingSender } from '@/graphql/hooks/clients/useTextingSenders';
import useUserSenders from '@/graphql/hooks/users/useUserSenders';
import useClientId from '@/hooks/router/useClientId';
import useMissionId from '@/hooks/router/useMissionId';
import { useRevealSenderStorage } from '@/revealComponents/ProfileContactFlow/Actions/EmailAction/useRevealEmailAction';
import { getSweetEvaluator } from '@/SweetEvaluator';
import { Variable } from '@/SweetEvaluator/types';
import { useQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

export type SkipProfileMessage = {
  subject: string;
  body: string;
  senderId: string;
};

type SkipWithEmailContextType = {
  selectedReason: string;
  handleSkip: () => void;
  onOpenPopup: (open: boolean) => void;
  selectedTemplateId: string;
  selectedSenderId: string;
  setSelectedSenderId: (_id: string) => void;
  emailPreviewOpen: boolean;
  setEmailPreviewOpen: (_open: boolean) => void;
  skipWithEmail: boolean;
  setSkipWithEmail: (_skip: boolean) => void;
  sendEmailModalOpen: boolean;
  setSendEmailModalOpen: (_open: boolean) => void;
  selectedTemplate: BaseMailTemplateFragmentWithSender | null;
  emailBody: string;
  setEmailBody: (_body: string) => void;
  emailSubject: string;
  setEmailSubject: (_subject: string) => void;
  selectedSender: { id: string; senderAddress: string } | null;
  onSelectTemplate: (templateId: string) => void;
  templates: BaseMailTemplateFragmentWithSender[];
  senders: readonly { id: string; senderAddress: string }[];
  loading: boolean;
  skipLoading?: boolean;
  isNotInstantiated: boolean;
};

export const SkipWithEmailContext = createContext<SkipWithEmailContextType>({
  selectedReason: '',
  handleSkip: () => {},
  onOpenPopup: () => {},
  selectedTemplateId: '',
  selectedSenderId: '',
  setSelectedSenderId: () => {},
  emailPreviewOpen: false,
  setEmailPreviewOpen: () => {},
  skipWithEmail: false,
  setSkipWithEmail: () => {},
  sendEmailModalOpen: false,
  setSendEmailModalOpen: () => {},
  selectedTemplate: null,
  emailBody: '',
  setEmailBody: () => {},
  emailSubject: '',
  setEmailSubject: () => {},
  selectedSender: null,
  onSelectTemplate: () => {},
  templates: [],
  senders: [],
  loading: false,
  skipLoading: false,
  isNotInstantiated: false,
});

type SkipWithEmailContextProviderProps = {
  selectedReason: string;
  handleSkip: (reason: string, message?: SkipProfileMessage) => Promise<void>;
  onOpenPopup: (open: boolean) => void;
  skipLoading?: boolean;
  children: ReactNode;
};

export type BaseMailTemplateFragmentWithSender = BaseMailTemplateFragment & {
  senderId: string;
};

export const SkipWithEmailContextProvider = ({
  selectedReason,
  handleSkip,
  onOpenPopup,
  skipLoading,
  children,
}: SkipWithEmailContextProviderProps) => {
  const { t } = useTranslation();
  const clientId = useClientId();

  const { profile, loadingProfile } = useCandidateViewContext();

  const missionId = useMissionId();

  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedSenderId, setSelectedSenderId] = useState('');
  const [emailPreviewOpen, setEmailPreviewOpen] = useState(false);
  const [skipWithEmail, setSkipWithEmail] = useState(false);
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [emailBody, setEmailBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');

  const isNotInstantiated = useMemo(() => {
    const hasForbiddenWords = (str: string) =>
      str.toLocaleLowerCase().includes('xxx') ||
      str.toLocaleLowerCase().includes('aucune valeur') ||
      str.toLocaleLowerCase().includes('no value');
    return hasForbiddenWords(emailBody) || hasForbiddenWords(emailSubject);
  }, [emailBody, emailSubject]);

  const { data: templatesData, loading } = useQuery(GET_CLIENT_TEMPLATES, {
    variables: { clientId },
  });

  const { data: userSendersData, loading: userSendersLoading } = useUserSenders(
    { granted: true, owned: true },
  );

  const [storedRevealSender] = useRevealSenderStorage();

  const templates = useMemo(
    () => templatesData?.client?.templates || [],
    [templatesData],
  );

  const selectedTemplate = useMemo(
    () => _.findWhere(templates, { id: selectedTemplateId }),
    [selectedTemplateId, templates],
  );

  const selectedSender = useMemo(
    () =>
      userSendersData?.user?.senders.find(
        (sender: TextingSender) =>
          sender.id === selectedSenderId ||
          sender.id === storedRevealSender?.senderId,
      ),
    [selectedSenderId, storedRevealSender, userSendersData?.user?.senders],
  );

  const mergeTagsProfileContext = useMergeTagsProfileContext({
    clientId,
    missionId: missionId || '',
    profile,
  });

  const instantiateEmail = useCallback(
    (templateId: string) => {
      const template = _.findWhere(templates, { id: templateId });
      if (!template) {
        console.error(`Template not found : ${templateId}`);
        return null;
      }

      const {
        subject: templateSubject,
        body: templateBody,
        snippets: templateSnippets,
      } = template;

      const sweetEvaluator = getSweetEvaluator({
        getExternalExpressionEvaluatorFromType,
      });

      const {
        subject: contactFlowSubject,
        body: contactFlowBody,
        snippets: contactFlowSnippets,
      } = MergeTagsService.replaceAllIds({
        subject: templateSubject || '',
        body: templateBody || '',
        subId: 'contactFlow',
        snippets: templateSnippets,
        t,
      });

      const instantiatedSnippets = sweetEvaluator.getUpdatedVariables({
        variables: contactFlowSnippets as Variable[],
        context: mergeTagsProfileContext,
      });

      const subjectFromTemplate = MergeTagsService.getInstantiatedText({
        text: MergeTagsService.getSnippetIdOrContextValueFromText({
          context: mergeTagsProfileContext,
          text: contactFlowSubject || '',
          instantiatedSnippets,
        }),
        context: mergeTagsProfileContext,
        instantiatedSnippets,
        t,
      });
      const text = MergeTagsService.getInstantiatedText({
        text: MergeTagsService.getSnippetIdOrContextValueFromText({
          context: mergeTagsProfileContext,
          text: contactFlowBody || '',
          instantiatedSnippets,
        }),
        context: mergeTagsProfileContext,
        instantiatedSnippets,
        t,
      });
      const sanitizedText = getHtmlStringAsText(text);
      return {
        body: sanitizedText,
        subject: subjectFromTemplate,
        snippets: instantiatedSnippets,
      };
    },
    [mergeTagsProfileContext, t, templates],
  );

  useEffect(() => {
    const localStorageTemplateId = localStorage.getItem(
      `skipEmailTemplate-${clientId}-${selectedReason}`,
    );
    const localStorageTemplate = _.findWhere(templates, {
      id: localStorageTemplateId,
    });
    const localStorageTemplateSender = localStorage.getItem(
      `skipEmailSender-${clientId}`,
    );
    const isChecked = localStorage.getItem(
      `skipWithEmail-${clientId}-${selectedReason}`,
    );
    if (localStorageTemplate) {
      const instantiatedEmail = instantiateEmail(localStorageTemplate.id);
      setEmailBody(instantiatedEmail?.body || '');
      setEmailSubject(instantiatedEmail?.subject || '');
      setSelectedTemplateId(localStorageTemplate.id);
    }
    if (localStorageTemplateSender) {
      setSelectedSenderId(localStorageTemplateSender);
    }
    if (isChecked) {
      setSkipWithEmail(true);
    }
  }, [clientId, instantiateEmail, selectedReason, templates]);

  const handleSkipWithSelectedReason = useCallback(async () => {
    if (!skipWithEmail) {
      await handleSkip(selectedReason);
      return;
    }
    localStorage.setItem(
      `skipEmailTemplate-${clientId}-${selectedReason}`,
      selectedTemplateId,
    );
    localStorage.setItem(`skipEmailSender-${clientId}`, selectedSenderId);
    localStorage.setItem(`skipWithEmail-${clientId}-${selectedReason}`, 'true');
    const htmlBody = emailBody.replaceAll('\n', '<br/>');
    await handleSkip(selectedReason, {
      body: htmlBody,
      subject: emailSubject,
      senderId: selectedSender.id,
    });
  }, [
    clientId,
    emailBody,
    emailSubject,
    handleSkip,
    selectedReason,
    selectedSender,
    selectedSenderId,
    selectedTemplateId,
    skipWithEmail,
  ]);

  const onSelectTemplate = useCallback(
    (templateId: string) => {
      const instantiatedEmail = instantiateEmail(templateId);
      if (!instantiatedEmail) {
        return;
      }
      const { body, subject } = instantiatedEmail;
      setSelectedTemplateId(templateId);
      setEmailBody(body);
      setEmailSubject(subject);
    },
    [instantiateEmail],
  );

  const providerValue = useMemo(
    () => ({
      selectedReason,
      handleSkip: handleSkipWithSelectedReason,
      onOpenPopup,
      selectedTemplateId,
      selectedSenderId,
      setSelectedSenderId,
      emailPreviewOpen,
      setEmailPreviewOpen,
      skipWithEmail,
      setSkipWithEmail,
      sendEmailModalOpen,
      setSendEmailModalOpen,
      selectedTemplate,
      selectedSender,
      emailBody,
      setEmailBody,
      emailSubject,
      setEmailSubject,
      onSelectTemplate,
      templates,
      senders: userSendersData?.user?.senders || [],
      loading: loadingProfile || loading || userSendersLoading,
      skipLoading,
      isNotInstantiated,
    }),
    [
      selectedReason,
      handleSkipWithSelectedReason,
      onOpenPopup,
      selectedTemplateId,
      selectedSenderId,
      emailPreviewOpen,
      skipWithEmail,
      sendEmailModalOpen,
      selectedTemplate,
      selectedSender,
      emailBody,
      emailSubject,
      onSelectTemplate,
      templates,
      userSendersData?.user?.senders,
      loadingProfile,
      loading,
      userSendersLoading,
      skipLoading,
      isNotInstantiated,
    ],
  );

  return (
    <SkipWithEmailContext.Provider value={providerValue}>
      {children}
    </SkipWithEmailContext.Provider>
  );
};
