import gql from 'graphql-tag';
import React, { useContext } from 'react';
import { MutationFunctionOptions, useMutation } from '@apollo/client';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import useClientId from '@/hooks/router/useClientId';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { AddProfileToSequenceContext } from '@/context/AddProfileToSequenceContext';
import { GET_REVEAL_SEARCH_POOL_RESULTS } from '@/hocs/searchPool/withRevealSearchPoolResults';
import ContactFlow from './fragments/ContactFlow';
import ProfileMissionInfoWithMission from './fragments/ProfileMissionInfoWithMission';
import ProfileProjectState from './fragments/ProfileProjectState';
import ProfileSequenceInfoWithSequence from './fragments/ProfileSequenceInfoWithSequence';
import SearchPoolProfileFragment from './fragments/SearchPoolProfile';
import Task from './fragments/Task';
import TimelineItem from './fragments/TimelineItem';
import useUserFromJWToken from './hooks/users/useUserFromJWToken';
import useUpdateCacheSequenceLastUse from './hooks/sequences/useUpdateCacheSequenceLastUse';
import useUpdateCacheMissionLastUse from './hooks/searchPoolJob/useUpdateCacheLastUse';
import { Sequence } from '../types/sequence';

export const ADD_PROFILES_TO_MISSION_AND_SEQUENCE = gql`
  mutation addProfilesToMissionAndSequence(
    $searchPoolId: ID!
    $input: AddProfilesToMissionAndSequenceInput!
  ) {
    searchPool(id: $searchPoolId) {
      addProfilesToMissionAndSequence(input: $input) {
        results {
          profileId
          success
          error
          profile {
            id
            currentProject {
              ...ProfileProjectState
            }
            currentSequenceInfo {
              ...ProfileSequenceInfoWithSequence
            }
            missionsInfo {
              ...ProfileMissionInfoWithMission
            }
            contactFlow {
              ...ContactFlow
            }
            contactData {
              timeline {
                ...TimelineItem
              }
            }
          }
        }
      }
    }
  }
  ${ContactFlow}
  ${ProfileProjectState}
  ${ProfileSequenceInfoWithSequence}
  ${ProfileMissionInfoWithMission}
  ${TimelineItem}
`;

type AddProfilesToMissionAndSequenceData = {
  searchPool: {
    addProfilesToMissionAndSequence: {
      results: {
        success: boolean;
        error?: string;
        profileId: string;
        profile?: SearchPoolProfile;
      }[];
    };
  };
};

type AddProfilesToMissionAndSequenceVariables = {
  searchPoolId: 'reveal';
  input: {
    missionId?: string;
    sequenceId?: string;
    profileIds: string[];
    user?: { firstname?: string; lastname?: string; email?: string };
    interruptCurrentSequence?: boolean;
    shouldScheduleFirstSequenceAction?: boolean;
    // shouldAddAsAdditionalSequence?: boolean;
  };
};

type AugmentatedAddProfilesToMissionAndSequenceMutationOptions =
  MutationFunctionOptions<
    AddProfilesToMissionAndSequenceData,
    AddProfilesToMissionAndSequenceVariables
  > & { sequence?: Sequence };

export function useAddProfilesToMissionAndSequenceMutation() {
  const clientId = useClientId();
  const user = useUserFromJWToken();
  const addProfileToSequenceContext = useContext(AddProfileToSequenceContext);
  const updateSequenceCache = useUpdateCacheSequenceLastUse();
  const updateMissionCache = useUpdateCacheMissionLastUse();

  const [mutation, mutationResult] = useMutation<
    AddProfilesToMissionAndSequenceData,
    AddProfilesToMissionAndSequenceVariables
  >(ADD_PROFILES_TO_MISSION_AND_SEQUENCE, {
    // Required to refresh recommendations count when archiving
    refetchQueries: [
      'getSearchPoolJobOptions',
      'getSearchPoolJobProfileResults',
      GET_REVEAL_SEARCH_POOL_RESULTS,
    ],
  });
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();

  const newMutation = React.useCallback(
    async (
      options: AugmentatedAddProfilesToMissionAndSequenceMutationOptions,
    ) => {
      const { sequence } = options;
      if (
        !addProfileToSequenceContext.checkIfProfileCanBeAddedToSequence(
          sequence,
        )
      ) {
        addProfileToSequenceContext.setModalOpen(true);
        return {};
      }

      const result = await mutation(options);

      const { variables } = options;
      const sequenceId = variables?.input?.sequenceId;

      if (sequenceId) {
        publishSubscriptionEvent('onProfileAddedToSequence', {
          profileIds: variables.input.profileIds,
          sequenceId,
        });
      }
      if (variables?.input?.missionId) {
        publishSubscriptionEvent('onProfilesAddedToMission', {
          profileIds: variables.input.profileIds,
          missionId: variables.input.missionId,
        });
      }

      if (!user?.data?.user.email) {
        return result;
      }

      const {
        data: {
          user: { email },
        },
      } = user;

      updateMissionCache({
        jobId: variables?.input?.missionId,
        authorEmail: email,
      });

      updateSequenceCache({
        clientId,
        authorEmail: email,
        sequenceId,
      });

      return result;
    },
    [
      mutation,
      addProfileToSequenceContext,
      publishSubscriptionEvent,
      user,
      clientId,
      updateMissionCache,
      updateSequenceCache,
    ],
  );

  return [newMutation, mutationResult] as const;
}

export const REMOVE_PROFILES_FROM_MISSION = gql`
  mutation removeProfilesFromMission(
    $searchPoolId: ID!
    $input: RemoveProfilesFromMissionInput!
  ) {
    searchPool(id: $searchPoolId) {
      removeProfilesFromMission(input: $input) {
        results {
          profileId
          success
          error
          profile {
            id
            missionsInfo {
              ...ProfileMissionInfoWithMission
            }
            contactData {
              timeline {
                ...TimelineItem
              }
            }
          }
        }
      }
    }
  }
  ${ProfileMissionInfoWithMission}
  ${TimelineItem}
`;

type RemoveProfilesFromMissionData = {
  searchPool: {
    removeProfilesFromMission: {
      results: {
        success: boolean;
        error?: string;
        profileId: string;
        profile: SearchPoolProfile;
      }[];
    };
  };
};

type RemoveProfilesFromMissionVariables = {
  searchPoolId: 'reveal';
  input: {
    profileIds: string[];
    missionId: string;
    shouldInterruptSequence?: boolean;
    answerLabels?: string[];
    sequenceId?: string;
  };
};

export const useRemoveProfilesFromMission = () => {
  const [mutation, mutationResult] = useMutation<
    RemoveProfilesFromMissionData,
    RemoveProfilesFromMissionVariables
  >(REMOVE_PROFILES_FROM_MISSION, {
    refetchQueries: ['getSegmentCounts', 'getSearchPoolJobProfileResults'],
  });
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const newMutation = React.useCallback(
    async (
      options: MutationFunctionOptions<
        RemoveProfilesFromMissionData,
        RemoveProfilesFromMissionVariables
      >,
    ) => {
      const result = await mutation(options);
      const { variables } = options;

      if (variables?.input?.profileIds && variables?.input?.missionId) {
        publishSubscriptionEvent('onProfilesRemovedFromMission', {
          profileIds: variables.input.profileIds,
          missionId: variables.input.missionId,
        });
      }

      return result;
    },
    [mutation, publishSubscriptionEvent],
  );

  return [newMutation, mutationResult] as const;
};

export const ADD_PROFILE_TO_SEARCH_POOL = gql`
  mutation addProfile($searchPoolId: ID!, $input: SearchPoolAddProfileInput!) {
    searchPool(id: $searchPoolId) {
      addProfile(input: $input) {
        profile {
          ...SearchPoolProfile
        }
        error {
          type
          duplicates {
            ...SearchPoolProfile
          }
        }
      }
    }
  }
  ${SearchPoolProfileFragment}
`;

export const CREATE_PROFILE_TASK = gql`
  mutation createProfileTask(
    $searchPoolId: ID!
    $input: CreateProfileTaskInput!
    $explicitTasksFilter: ExplicitTasksFilter
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      createTask(input: $input) {
        profile {
          id
          explicitTasks(filter: $explicitTasksFilter) {
            ...Task
          }
        }
      }
    }
  }
  ${Task}
`;

export const UPDATE_PROFILE_TASK = gql`
  mutation updateProfileTask(
    $searchPoolId: ID!
    $input: UpdateProfileTaskInput!
    $explicitTasksFilter: ExplicitTasksFilter
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateTask(input: $input) {
        profile {
          id
          explicitTasks(filter: $explicitTasksFilter) {
            ...Task
          }
        }
      }
    }
  }
  ${Task}
`;

export const REGISTER_REVEAL_PROFILE_VIEW = gql`
  mutation registerView(
    $searchPoolId: ID!
    $profileId: ID!
    $origin: ViewOriginInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      registerView(profileId: $profileId, origin: $origin) {
        id
        views {
          timeline {
            author {
              lastname
              firstname
              email
              photoLink
            }
            date
            timestamp
            origin {
              type
              URL
            }
          }
          statsByRecruiter {
            author {
              firstname
              lastname
              email
              photoLink
            }
            firstViewTimestamp
            lastViewTimestamp
            count
          }
        }
      }
    }
  }
`;

export const REGISTER_PROFILE_VIEW = gql`
  mutation RegisterView($profileId: ID!, $origin: ViewOriginInput!) {
    registerView(profileId: $profileId, origin: $origin) {
      id
      views {
        timeline {
          author {
            lastname
            firstname
            email
            photoLink
          }
          timestamp
          date
          origin {
            type
            URL
          }
        }
        statsByRecruiter {
          author {
            lastname
            firstname
            email
            photoLink
          }
          firstViewTimestamp
          lastViewTimestamp
          count
        }
      }
    }
  }
`;

export const LOCK_CURRENT_SEQUENCE_VARIABLE = gql`
  mutation LockCurrentSequenceVariable(
    $searchPoolId: ID!
    $input: LockCurrentSequenceVariableInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      lockCurrentSequenceVariable(input: $input) {
        profile {
          id
          contactFlow {
            ...ContactFlow
          }
        }
      }
    }
  }
  ${ContactFlow}
`;

export interface ReapplySequenceToProfilesVariables {
  searchPoolId: string;
  input: {
    profileIds: string[];
    sequenceId: string;
    numberOfProfileActionsToKeep: number;
    sequenceSuffixIndex: number;
  };
}

export interface ReapplySequenceToProfilesResults {
  searchPool: {
    reapplySequenceToProfiles: {
      results: {
        profileId?: string;
        success?: boolean;
        error?: string;
        profile?: unknown[];
      }[];
    };
  };
}

export const REAPPLY_SEQUENCE_TO_PROFILES = gql`
  mutation reapplySequenceToProfiles(
    $searchPoolId: ID!
    $input: ReapplySequenceToProfilesInput!
  ) {
    searchPool(id: $searchPoolId) {
      reapplySequenceToProfiles(input: $input) {
        results {
          profileId
          success
          error
          profile {
            id
            currentSequenceInfo {
              ...ProfileSequenceInfoWithSequence
            }
            contactFlow {
              ...ContactFlow
            }
          }
        }
      }
    }
  }
  ${ContactFlow}
  ${ProfileSequenceInfoWithSequence}
`;
