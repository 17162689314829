import gql from 'graphql-tag';

export default gql`
  fragment MicroTask on Task {
    id
    type
    subtype
    state
    queueId
    sequenceId
    missionId
    origin
    dueDate
    referenceDueDate

    title {
      default
    }

    description {
      default
    }

    owner {
      email
      firstname
      lastname
    }

    synchronizeWithATS
  }
`;

