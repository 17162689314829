import { Modal } from '@hiresweet/hiresweet-lib';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'underscore';
import GenericTextInput from '@/components/Common/GenericTextInput';
import ButtonDropdownMenu from '@/components/Common/ButtonDropdownMenu';
import GenericTextArea from '@/components/Common/GenericTextArea';
import GenericFormLabel from '@/components/Common/GenericFormLabel';
import GenericSelect from '@/components/Common/GenericSelect';
import GenericButton from '@/components/Common/GenericButton';
import GenericTooltip from '@/components/Common/GenericTooltip';
import styles from './SkipWithEmailModal.module.less';
import { SkipWithEmailContext } from '../SkipWithEmailContext/SkipWithEmailContext';

const SkipWithEmailModal = () => {
  const { t } = useTranslation();

  const {
    sendEmailModalOpen,
    setSendEmailModalOpen,
    handleSkip,
    onOpenPopup,
    selectedTemplate,
    selectedSender,
    setSelectedSenderId,
    emailBody,
    setEmailBody,
    emailSubject,
    setEmailSubject,
    onSelectTemplate,
    templates,
    senders,
    loading,
    skipLoading,
    isNotInstantiated,
  } = useContext(SkipWithEmailContext);

  const senderOptions = _.map(senders, (sender) => ({
    value: sender.id,
    label: sender.senderAddress,
  }));

  if (loading) {
    return null;
  }

  return (
    <Modal
      className={styles.modal}
      open={sendEmailModalOpen}
      title={t('reveal.applicantProfiles.skip.skipWithEmail')}
      onClose={() => {
        onOpenPopup(true);
        setSendEmailModalOpen(false);
      }}
      disabled={
        !selectedTemplate ||
        !selectedSender ||
        !emailBody ||
        !emailSubject ||
        skipLoading ||
        isNotInstantiated
      }
      onSubmit={handleSkip}
      submitText={t('reveal.applicantProfiles.skip.sendEmail')}
      closeText={t('common.cancel')}
      size='medium'
      hideFooter
    >
      <div onClick={(e) => e.stopPropagation()}>
        <div className={styles.formItem}>
          <GenericFormLabel className={styles.label}>
            {t('profile.contact.timeline.sobo')}
          </GenericFormLabel>
          <GenericSelect
            options={senderOptions}
            value={_.findWhere(senderOptions, {
              value: selectedSender?.id,
            })}
            onChange={(sender) => setSelectedSenderId(sender?.value || '')}
          />
        </div>
        <div className={styles.formItem}>
          <GenericFormLabel className={styles.label}>
            {t('reveal.applicantProfiles.skip.subject')}
          </GenericFormLabel>
          <GenericTextInput
            className={styles.subject}
            onValue={setEmailSubject}
            value={emailSubject}
            placeholder={t('reveal.applicantProfiles.skip.subjectPlaceholder')}
          />
        </div>
        <div className={styles.formItem}>
          <GenericFormLabel className={styles.label}>
            {t('reveal.applicantProfiles.skip.body')}
          </GenericFormLabel>
          <GenericTextArea
            className={styles.textarea}
            placeholder={t('reveal.applicantProfiles.skip.bodyPlaceholder')}
            rows={8}
            value={emailBody}
            onValue={(value) => setEmailBody(value)}
          />
        </div>
        <ButtonDropdownMenu
          upward
          primacy='secondary'
          title={t('editor.chooseATemplate')}
          options={_.map(templates, (template) => ({
            id: template.id,
            label: template.title,
          }))}
          onSelect={onSelectTemplate}
        />
        <div className={styles.footer}>
          <GenericButton
            primacy='secondary'
            onClick={() => {
              onOpenPopup(true);
              setSendEmailModalOpen(false);
            }}
            className={styles.cancelButton}
          >
            {t('common.cancel')}
          </GenericButton>
          {isNotInstantiated ? (
            <GenericTooltip
              on='hover'
              position='top center'
              trigger={
                <GenericButton
                  onClick={() => {}}
                  className={styles.disabledButton}
                >
                  {t('reveal.applicantProfiles.skip.skip')}
                </GenericButton>
              }
            >
              {t('reveal.applicantProfiles.skip.formErrors.emptySnippets')}
            </GenericTooltip>
          ) : (
            <GenericButton
              onClick={() => {
                handleSkip();
              }}
              disabled={
                !selectedTemplate ||
                !selectedSender ||
                !emailBody ||
                !emailSubject ||
                skipLoading
              }
            >
              {t('reveal.applicantProfiles.skip.skip')}
            </GenericButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SkipWithEmailModal;
