import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';

import UnsubscribedPill from '@/components/Reveal/Profile/privacy/UnsubscribedPill/UnsubscribedPill';
import sanitizeTimeLineItem from '../../../../common/sanitizeTimeLineItem';

import Thumbnail from '../../../../components/Thumbnail';

import withUserFromJWToken from '../../../../hocs/users/withUserFromJWToken';
import { TASK_TYPES } from '../../../../common/constants/taskTypes';
import DefaultActionItem from './DefaultActionItem';

import Meeting from './Meeting';
import CommentTimelineItem from './Comment';
import MissionItem from './MissionItem';
import SkippedFromMissionRecommendationsItem from './SkippedFromMissionRecommendationsItem';
import LogActivityTimelineItemConnected from './LogActivityTimelineItem.connected';
import SourcedTimelineItem from './Sourced';
import StepTimelineItem from './StepTimelineItem';
import MilestoneTimelineItem from './MilestoneTimelineItem';
import PluginImportItem from './PluginImport';
import ManualImportItem from './ManualImport';
import CSVImportItem from './CSVImport';
import EnrolledInSequenceItem from './EnrolledInSequence';
import UnenrolledFromSequenceItem from './UnenrolledFromSequence';
import ApplicationItem from './ApplicationItem';
import EmailTrackingPopup from './EmailTrackingPopup';
import TaskCompletedItem from './TaskCompleted';
import TaskSnoozedItem from './TaskSnoozed';
import MessageItem from './MessageItem';
import GenericAtsTimelineItem from './GenericAtsTimelineItem/GenericAtsTimelineItem';
import ScoreCardTimelineItem from './ScoreCardTimelineItem';
import CampaignMessageItem from './CampaignMessage';
import TASK_ICONS from './TaskIcons';
import MessageUnavailableTimelineItem from './MessageUnavailable';
import DoNotContactItem from './DoNotContactItem';
import EnrolledInCampaignItem from './EnrolledInCampaign';
import UnenrolledFromCampaignItem from './UnenrolledFromCampaign';
import TextMessageItem from './TextMessageItem';

import './commonStyles/sanitize.css';
import './TimeLineItem.css';
import UnipileMessageItem from './UnipileMessageItem/UnipileMessageItem';
import SkippedFromMissionApplicationsItem from './SkippedFromMissionApplicationsItem';

const unexpandedBodyMaxHeight = 150;

const getMessageSequenceBody = (action) => {
  const res = `<div>${_.reduce(
    action.messages,
    (memo, { body }) => {
      const inner = body;
      return memo + (memo !== '' ? '<br/><hr><br/>' : '') + inner;
    },
    '',
  )}</div>`;
  return res;
};

const getCardElt = ({ action, date }, t) => {
  if (action.type === 'message') {
    return {
      specificCardClass: '',
      cardBodyTitle: action.message ? action.message.subject : '',
      cardBody: action.message ? action.message.body : '',
    };
  }
  if (action.type === 'comment') {
    return {
      specificCardClass: 'comment',
      cardBodyTitle: '',
      cardBody: action.comment ? action.comment.text : '',
    };
  }
  if (action.type === 'messageSequence') {
    const trOptions = {
      date: new Date(+date),
    };
    const formattedDate = +date
      ? t('common.formattedDate', trOptions)
      : t('common.defaultDuration');
    return {
      specificCardClass: 'messageSequence',
      cardBodyTitle: `${t(
        'profile.contact.timeline.followupPlanned',
      )} ${formattedDate} (${t('profile.contact.timeline.ifNoAnswer')})`,
      cardBody: getMessageSequenceBody(action),
    };
  }
  return {
    specificCardClass: '',
    cardBodyTitle: '',
    cardBody: '',
  };
};

const TimeLineBody = React.memo(({ content }) => {
  return (
    <div
      className='body-content'
      dangerouslySetInnerHTML={{ __html: sanitizeTimeLineItem(content) }}
    />
  );
});
class TimeLineItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setOverflowStatus = this.setOverflowStatus.bind(this);
    this.showMoreClicked = this.showMoreClicked.bind(this);
    this.state = {
      isBodyOverflown: false,
      showMoreClicked: false,
      maxUnexpandedHeight: `${unexpandedBodyMaxHeight.toString()}px`,
      realHeight: null, // `${unexpandedBodyMaxHeight.toString()}px`,
      size: null,
    };
    this.textAreaStyle = {};
  }

  setOverflowStatus(element) {
    if (element) {
      if (element.scrollHeight <= unexpandedBodyMaxHeight) {
        this.textAreaStyle.height = element.scrollHeight;
        this.setState({
          isBodyOverflown: false,
          size: `${element.scrollHeight}px`,
          realHeight: null,
        });
      } else {
        this.textAreaStyle.height = unexpandedBodyMaxHeight;
        this.setState({
          isBodyOverflown: true,
          realHeight: `${element.scrollHeight}px`,
          size: null,
        });
      }
    }
  }

  showMoreClicked() {
    if (this.state.showMoreClicked) {
      this.setState({
        showMoreClicked: false,
      });
    } else {
      this.setState({
        showMoreClicked: true,
      });
    }
  }

  render() {
    const {
      timelineItem,
      profile,
      user,
      clientId,
      searchPoolId,
      openMessageModal,
      applicationMode = 'classic',
      t,
    } = this.props;
    if (!profile) {
      return null;
    }
    const {
      author = {
        photoLink: null,
        firstname: null,
        lastname: null,
        email: null,
      },
    } = timelineItem;

    if (timelineItem.action.type === TASK_TYPES.SEND_EMAIL_MANUAL) {
      return null;
    }
    const blurEffectActive =
      this.state.isBodyOverflown && !this.state.showMoreClicked;
    if (this.state.isBodyOverflown) {
      if (this.state.showMoreClicked) {
        this.textAreaStyle.height = this.state.realHeight;
      } else {
        this.textAreaStyle.height = this.state.maxUnexpandedHeight;
      }
    } else {
      this.textAreaStyle.height = this.state.size;
    }
    const { specificCardClass, cardBodyTitle, cardBody } = getCardElt(
      timelineItem,
      t,
    );
    const unbiasedModeEnabled =
      profile?.resumeData?.step === 'pending' && user?.unbiasedModeEnabled;
    if (timelineItem && timelineItem.action) {
      const { type } = timelineItem.action;
      if (type === 'sourced') {
        return (
          <SourcedTimelineItem
            profile={profile}
            unbiasedModeEnabled={unbiasedModeEnabled}
            clientId={clientId}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'plugin-import') {
        return (
          <PluginImportItem
            profile={profile}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'app-manual-import') {
        return (
          <ManualImportItem
            profile={profile}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'app-csv-import') {
        return (
          <CSVImportItem
            profile={profile}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'reveal-plugin-import') {
        return (
          <PluginImportItem
            profile={profile}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'reveal-csv-import') {
        return (
          <CSVImportItem
            profile={profile}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'career-page-application') {
        return (
          <ApplicationItem
            profile={profile}
            item={timelineItem}
            offerId={timelineItem?.action.jobOfferId}
            unbiasedModeEnabled={unbiasedModeEnabled}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'added-to-mission') {
        return (
          <MissionItem
            profile={profile}
            timelineItem={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            missionAction='addedToMission'
            author={author}
            applicationMode={applicationMode}
            clientId={clientId}
          />
        );
      }
      if (type === 'removed-from-mission') {
        return (
          <MissionItem
            profile={profile}
            timelineItem={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            missionAction='removedFromMission'
            author={author}
            applicationMode={applicationMode}
            clientId={clientId}
          />
        );
      }
      if (type === 'accepted-from-mission-recommendations') {
        return (
          <MissionItem
            profile={profile}
            timelineItem={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            missionAction='acceptedFromMissionRecommendations'
            author={author}
            applicationMode={applicationMode}
            clientId={clientId}
          />
        );
      }
      if (type === 'accepted-from-mission-applications') {
        return (
          <MissionItem
            profile={profile}
            timelineItem={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            missionAction='acceptedFromMissionApplications'
            author={author}
            applicationMode={applicationMode}
            clientId={clientId}
          />
        );
      }
      if (type === 'mission-stage-updated') {
        return (
          <MissionItem
            profile={profile}
            timelineItem={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            missionAction='missionStageUpdated'
            author={author}
            applicationMode={applicationMode}
            clientId={clientId}
          />
        );
      }
      if (
        type === 'marked-as-do-not-contact' ||
        type === 'unmarked-as-do-not-contact'
      ) {
        return (
          <DoNotContactItem
            profile={profile}
            timelineItem={timelineItem}
            clientId={clientId}
          />
        );
      }
      if (type === 'skipped-from-mission-recommendations') {
        return (
          <SkippedFromMissionRecommendationsItem
            profile={profile}
            timelineItem={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'skipped-from-mission-applications') {
        return (
          <SkippedFromMissionApplicationsItem
            profile={profile}
            timelineItem={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            author={author}
          />
        );
      }

      if (type === 'enrolled-in-sequence') {
        return (
          <EnrolledInSequenceItem
            profile={profile}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            author={author}
            applicationMode={applicationMode}
            clientId={clientId}
          />
        );
      }
      if (type === 'enrolled-in-campaign') {
        return (
          <EnrolledInCampaignItem
            profile={profile}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            author={author}
            applicationMode={applicationMode}
            clientId={clientId}
          />
        );
      }
      if (type === 'unenrolled-from-sequence') {
        return (
          <UnenrolledFromSequenceItem
            profile={profile}
            clientId={clientId}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'unenrolled-from-campaign') {
        return (
          <UnenrolledFromCampaignItem
            profile={profile}
            clientId={clientId}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'task-completed') {
        return (
          <TaskCompletedItem
            profile={profile}
            item={timelineItem}
            isBodyOverflown={this.state.isBodyOverflown}
            showMoreClicked={this.state.showMoreClicked}
            onShowMoreClicked={this.showMoreClicked}
            setOverflowStatus={this.setOverflowStatus}
            textAreaStyle={this.textAreaStyle}
            blurEffectActive={blurEffectActive}
            unbiasedModeEnabled={unbiasedModeEnabled}
            author={author}
            applicationMode={applicationMode}
            clientId={clientId}
          />
        );
      }
      if (type === 'task-snoozed') {
        return (
          <TaskSnoozedItem
            profile={profile}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            isBodyOverflown={this.state.isBodyOverflown}
            showMoreClicked={this.state.showMoreClicked}
            onShowMoreClicked={this.showMoreClicked}
            setOverflowStatus={this.setOverflowStatus}
            textAreaStyle={this.textAreaStyle}
          />
        );
      }
      if (type === 'comment') {
        return (
          <CommentTimelineItem
            clientId={clientId}
            profile={profile}
            timelineItem={timelineItem}
            author={author}
            applicationMode={applicationMode}
            searchPoolId={searchPoolId}
          />
        );
      }
      if (type === 'logged-activity') {
        return (
          <LogActivityTimelineItemConnected
            searchPoolId={searchPoolId}
            clientId={clientId}
            timelineItem={timelineItem}
            profile={profile}
            unbiasedModeEnabled={unbiasedModeEnabled}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'messageSequence') {
        return null;
      }
      if (type === 'step') {
        return (
          <StepTimelineItem
            profile={profile}
            timelineItem={timelineItem}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'message') {
        return (
          <MessageItem
            profile={profile}
            item={timelineItem}
            isBodyOverflown={this.state.isBodyOverflown}
            showMoreClicked={this.state.showMoreClicked}
            onShowMoreClicked={this.showMoreClicked}
            setOverflowStatus={this.setOverflowStatus}
            textAreaStyle={this.textAreaStyle}
            blurEffectActive={blurEffectActive}
            author={author}
            cardBody={cardBody}
            cardBodyTitle={cardBodyTitle}
            applicationMode={applicationMode}
            openMessageModal={openMessageModal}
          />
        );
      }
      if (type === 'campaign-message') {
        return (
          <CampaignMessageItem
            profile={profile}
            item={timelineItem}
            unbiasedModeEnabled={unbiasedModeEnabled}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'milestone') {
        return (
          <MilestoneTimelineItem
            clientId={clientId}
            profile={profile}
            timelineItem={timelineItem}
          />
        );
      }
      if (type === 'messageUnavailable') {
        return (
          <MessageUnavailableTimelineItem
            profile={profile}
            timelineItem={timelineItem}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'plannedMeetingAction') {
        return (
          <Meeting
            actionType={type}
            timelineItem={timelineItem}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'cancelledMeetingAction') {
        return (
          <Meeting
            actionType={type}
            timelineItem={timelineItem}
            author={author}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'atsActivity') {
        return (
          <GenericAtsTimelineItem
            profile={profile}
            timelineItem={timelineItem}
            applicationMode={applicationMode}
          />
        );
      }
      if (type === 'scorecard') {
        return <ScoreCardTimelineItem timelineItem={timelineItem} />;
      }
      if (type === 'text-message') {
        return <TextMessageItem item={timelineItem} profile={profile} />;
      }
      if (
        type === 'unipile-message' ||
        type === TASK_TYPES.WHATSAPP_SEND_MESSAGE
      ) {
        return (
          <UnipileMessageItem
            item={timelineItem}
            profile={profile}
            isBodyOverflown={this.state.isBodyOverflown}
            showMoreClicked={this.state.showMoreClicked}
            onShowMoreClicked={this.showMoreClicked}
            setOverflowStatus={this.setOverflowStatus}
            textAreaStyle={this.textAreaStyle}
            openMessageModal={openMessageModal}
          />
        );
      }
      // if (type === 'profileView') {
      //   return (
      //     <ProfileViewItem
      //       profile={profile}
      //       timelineItem={timelineItem}
      //       applicationMode={applicationMode}
      //     />
      //   );
      // }
      if (_.contains(_.values(TASK_TYPES), type)) {
        return (
          <DefaultActionItem
            action={timelineItem?.action}
            profile={profile}
            item={timelineItem}
            applicationMode={applicationMode}
          />
        );
      }
    }

    if (
      ['comment', 'message', 'messageSequence', 'step'].indexOf(
        timelineItem.action.type,
      ) < 0
    ) {
      return null;
    }

    const from = timelineItem.action.from || timelineItem.author.email;

    const authorSeemsToBeProfile =
      from &&
      profile.email &&
      from.replace(/\./g, '') === profile.email.replace(/\./g, '');

    const photoLink =
      timelineItem.author.photoLink ||
      (authorSeemsToBeProfile && profile?.resumeData?.photoLink);

    const translatedDateOrDuration = +timelineItem.date
      ? t('common.formattedDate', {
          date: new Date(+timelineItem.date),
        })
      : t('common.defaultDuration');
    return (
      <div className='timeline-card-mail-wrapper'>
        {/* <div className='left-border' /> */}
        <div className='content'>
          <div className='timeline-line' />
          <div className='header'>
            <div className='icon-container'>
              <div
                style={{
                  backgroundColor: TASK_ICONS[timelineItem.action.type]?.color,
                }}
                className='icon'
              >
                <i
                  className={`${
                    TASK_ICONS[timelineItem.action.type]?.icon
                  } ri-2x`}
                />
              </div>
            </div>
            <Thumbnail
              link={photoLink}
              firstname={timelineItem.author.firstname}
              lastname={timelineItem.author.lastname}
            />
            <div className='author'>
              {timelineItem.action.type === 'messageSequence' ? (
                <span>
                  {from ? `${t('profile.contact.timeline.from')} ${from} ` : ''}
                  {`${t('profile.contact.timeline.to')} ${
                    timelineItem.action.to || profile.email
                  }`}
                </span>
              ) : timelineItem.action.from || timelineItem.action.to ? (
                <span>
                  {timelineItem.action.from
                    ? `${t('profile.contact.timeline.from')} ${
                        timelineItem.action.from
                      } `
                    : ''}
                  {timelineItem.action.to
                    ? `${t('profile.contact.timeline.to')} ${
                        timelineItem.action.to
                      }`
                    : ''}
                </span>
              ) : (
                <span>
                  {timelineItem.author.firstname} {timelineItem.author.lastname}
                </span>
              )}
              {_.findWhere(timelineItem.action?.events || [], {
                type: 'click-unsubscribe-link',
              }) ? (
                <UnsubscribedPill showLabel />
              ) : (
                ''
              )}
              <div className='date'>{translatedDateOrDuration}</div>
            </div>
            <EmailTrackingPopup action={timelineItem.action} />
          </div>
          <div className={`card-content ${specificCardClass}`}>
            <div className='row'>
              {cardBodyTitle && <div className='title'>{cardBodyTitle}</div>}
              <div className='spacer' />
              {this.state.isBodyOverflown ? (
                !this.state.showMoreClicked ? (
                  <div className='show-more' onClick={this.showMoreClicked}>
                    {t('profile.contact.timeline.seeMore')}
                  </div>
                ) : (
                  <div className='show-more' onClick={this.showMoreClicked}>
                    {t('profile.contact.timeline.seeLess')}
                  </div>
                )
              ) : null}
            </div>
            <div
              ref={this.setOverflowStatus}
              className='body'
              style={{
                ...(this.textAreaStyle.height && {
                  height: this.textAreaStyle.height,
                }),
              }}
            >
              {blurEffectActive && <div className='blur-effect' />}
              <TimeLineBody content={cardBody} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withUserFromJWToken,
  withTranslation('translations'),
)(TimeLineItem);
