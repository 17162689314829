import React, { useEffect, useMemo, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import _ from 'underscore';

import { useTranslation } from 'react-i18next';
import useMergeTagsVariablesColors from '@/revealComponents/ProfileContactFlow/Sequences/SequenceDynamicVariables/hooks/useMergeTagsVariablesColors';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';

import { SNIPPET_TYPES } from '@/common/mergeTags/utils';
import SequenceVariablePopup from '@/revealComponents/ProfileContactFlow/Sequences/SequenceDynamicVariables/components/SequenceVariablePopup';
import { stripHtmlFromTextAndLinesBreaks } from '@/common/utils/string';
import styles from './Placeholder.module.less';
import FragmentPopupContent from './FragmentPopupContent';
import { getStatementTextMode } from '../VariableExamplesPreviewModal/ConditionsChaining/utils';

const ContactFlowPlaceholder = (props) => {
  const {
    offsetKey,
    children,
    contentState,
    entityKey,
    snippets,
    setIsReadOnly,
    clientId,
    clientSnippets,
    handleUnsyncFragment,
  } = props;

  const { t } = useTranslation();
  const [snippet, setSnippet] = useState(null);
  const [open, setIsOpen] = useState(false);
  const [clientFragment, setClientFragment] = useState(null);
  const [clientFragmentHTML, setClientFragmentHTML] = useState('');
  const { backgroundColor, variableValueColor } =
    useMergeTagsVariablesColors(snippet);

  useEffect(() => {
    if (!snippets) {
      return;
    }
    const entity = contentState.getEntity(entityKey);
    const key = entity?.data?.key;

    const matchedSnippet = _.find(snippets, (sn) => {
      return sn?.id === key;
    });

    if (!matchedSnippet) {
      return;
    }

    if (matchedSnippet.type === SNIPPET_TYPES.SYNCED_FRAGMENT) {
      const clientSyncedFragment = _.find(clientSnippets, (clientSnippet) => {
        return (
          clientSnippet.id === key.split('_')[0] &&
          clientSnippet.type === SNIPPET_TYPES.FRAGMENT
        );
      });
      if (clientSyncedFragment) {
        setClientFragmentHTML(
          matchedSnippet.state?.value ||
            matchedSnippet.fallbackValue?.text ||
            '',
        );
        setClientFragment(clientSyncedFragment);
      }
    } else {
      setSnippet(matchedSnippet);
    }
  }, [entityKey, snippets, contentState, t, clientSnippets]);

  const handleOpen = () => {
    setIsReadOnly(true);
    setIsOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setIsReadOnly(false);
    setIsOpen(false);
  };

  const afterSubmit = () => {
    setIsOpen(false);
    setIsReadOnly(false);
  };

  const handleUnsyncFragmentClick = () => {
    setIsOpen(false);
    handleUnsyncFragment(clientFragment);
  };

  const displayedValue = useMemo(() => {
    return MergeTagsService.getSnippetDisplayedValue({ snippet, t });
  }, [snippet, t]);

  const getSpanDisplayed = () => {
    if (clientFragment) {
      return (
        <span
          spellCheck={false}
          data-offset-key={offsetKey}
          readOnly
          contentEditable={false}
          className={styles.fragmentPlaceholder}
          dangerouslySetInnerHTML={{
            __html: clientFragmentHTML,
          }}
        />
      );
    }
    if (snippet?.type === SNIPPET_TYPES.CONDITIONS_CHAINING) {
      const textMode = getStatementTextMode(displayedValue);
      if (textMode === 'rich-editor') {
        return (
          <span
            spellCheck={false}
            data-offset-key={offsetKey}
            readOnly
            contentEditable={false}
            className={styles.fragmentPlaceholder}
            dangerouslySetInnerHTML={{
              __html: displayedValue,
            }}
          />
        );
      }
    }
    return (
      <span
        spellCheck={false}
        data-offset-key={offsetKey}
        readOnly
        contentEditable={false}
        style={{
          backgroundColor,
          padding: '3px 7px',
          borderRadius: '1em',
          color: '#1F2E77',
          fontWeight: '500',
          cursor: snippet ? 'pointer' : 'initial',
        }}
      >
        {!snippet ? (
          <span>{children}</span>
        ) : (
          <span style={{ color: variableValueColor }}>
            {snippet?.fallbackValue?.text && !snippet.state?.value && (
              <img
                alt='arrow'
                src='/images/icons/fallback-yellow.svg'
                style={{ width: '14px', marginRight: '10px' }}
              />
            )}
            {stripHtmlFromTextAndLinesBreaks(displayedValue)}
          </span>
        )}
      </span>
    );
  };

  const span = getSpanDisplayed();

  if (!snippet && !clientFragment) {
    return span;
  }

  return (
    <Popup
      open={open}
      style={{ padding: '0px' }}
      className={clientFragment ? styles.fragmentPopupWrapper : ''}
      content={
        clientFragment ? (
          <FragmentPopupContent
            clientFragment={clientFragment}
            onUnsyncFragment={handleUnsyncFragmentClick}
            t={t}
          />
        ) : (
          <SequenceVariablePopup
            clientId={clientId}
            variable={snippet}
            afterSubmit={afterSubmit}
            withPopupContentOnly
          />
        )
      }
      on='click'
      trigger={span}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

export default ContactFlowPlaceholder;
