import _ from 'underscore';

import { useMemo } from 'react';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { useClientMissionCustomFields } from '@/graphql/hooks/clients/useClientMissionCustomFields';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import useClientSnippets from '@/graphql/hooks/snippets/useClientSnippets';
import { Context } from '@/SweetEvaluator/types';
import { combineDataWithCustomFields } from '../customFields';

const useMergeTagsProfileContext = ({
  clientId,
  missionId,
  profile,
}: {
  clientId: string;
  missionId?: string;
  profile: any;
}): Record<string, unknown> => {
  const { profileCustomFields: clientCustomFields } =
    useClientProfileCustomFields(clientId);

  const { missionCustomFields } = useClientMissionCustomFields(clientId);
  const { data: clientSnippets } = useClientSnippets();
  const { jobOptions: missions } = useSearchPoolJobOptions(
    'reveal',
    {},
    'cache-first',
  );

  const mergeTagsProfileContext = useMemo(() => {
    if (!profile) {
      return {};
    }
    const context = { ...(profile.resumeData || {}) };

    if (
      !_.isEmpty(profile.resumeData?.customFields) &&
      !_.isEmpty(clientCustomFields)
    ) {
      const details = combineDataWithCustomFields(
        clientCustomFields,
        profile.resumeData,
      );
      const customFieldDict: Context['customFields'] = {};
      _.each(details, (detail) => {
        customFieldDict[detail.clientCustomFieldId] = {
          type: detail.type,
          value: detail.readableValue?.toString(),
        };
      });
      context.customFields = customFieldDict;
    }

    if (!_.isEmpty(clientSnippets?.snippets)) {
      const clientVariablesDict: Context['externalSnippets'] = {};
      _.each(clientSnippets?.snippets || [], (clientDynamicVariable) => {
        clientVariablesDict[clientDynamicVariable.id] = {
          ...clientDynamicVariable,
        };
      });
      context.externalSnippets = {
        ...clientVariablesDict,
      };
    }
    const currentSequence = _.findWhere(profile.contactFlow?.sequences || [], {
      isCurrent: true,
    });
    const linkedMission = _.findWhere(missions || [], {
      id: currentSequence?.linkedMissionId || missionId,
    });

    if (linkedMission) {
      context.linkedMission = {
        owner: {
          firstname: linkedMission.owner?.firstname,
          lastname: linkedMission.owner?.lastname,
          email: linkedMission.owner?.email,
        },
        title: linkedMission.data?.title,
      };
      if (
        !_.isEmpty(linkedMission?.customFields) &&
        !_.isEmpty(missionCustomFields)
      ) {
        const details = combineDataWithCustomFields(
          missionCustomFields,
          linkedMission as any,
        );
        const customFieldDict: Record<string, unknown> = {};
        _.each(details, (detail) => {
          customFieldDict[detail.clientCustomFieldId] = {
            type: detail.type,
            value: detail.readableValue?.toString(),
          };
        });
        context.missionCustomFields = customFieldDict;
      }
    }
    return context;
  }, [
    profile,
    clientCustomFields,
    missions,
    missionCustomFields,
    clientSnippets,
    missionId,
  ]);

  return mergeTagsProfileContext;
};

export default useMergeTagsProfileContext;
