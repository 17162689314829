import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import SearchPoolProfile from '@/graphql/fragments/SearchPoolProfile';

const skipApplicationProfile = gql`
  mutation skipApplicationMission(
    $searchPoolId: ID!
    $profileId: ID!
    $missionId: ID!
    $reason: String
    $message: SkipApplicationMissionMessageInput
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      skipApplicationMission(
        profileId: $profileId
        missionId: $missionId
        reason: $reason
        message: $message
      ) {
        ...SearchPoolProfile
      }
    }
  }
  ${SearchPoolProfile}
`;

export function useSkipApplicationProfileMutation(
  searchPoolId: string,
  missionId: string,
) {
  const [mutate, state] = useMutation(skipApplicationProfile, {
    refetchQueries: ['getApplicantProfiles'],
  });

  return [
    (
      profileId: string,
      reason?: string,
      message?: unknown,
      options?: Record<string, unknown>,
    ) =>
      mutate({
        ...options,
        variables: { searchPoolId, missionId, profileId, reason, message },
      }),
    state,
  ] as const;
}
