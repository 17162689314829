/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */

import _ from 'underscore';

import {
  Context,
  Variable,
  VariablesUpdate,
  ExpressionEvaluatorFromType,
  EvaluatorInterface,
} from '../types';
import { NO_VARIABLE_FOUND_ERROR_MESSAGE } from '../utils/constants';

import { getExpressionEvaluatorFromType } from './expressionEvaluators';

type VisitStatusFromId = Record<string, string>;
type NewStateFromVariableId = Record<string, { value: string | undefined }>;

export default class SweetEvaluator {
  private getExternalExpressionEvaluatorFromType: ExpressionEvaluatorFromType | null;

  constructor({
    getExternalExpressionEvaluatorFromType,
  }: {
    getExternalExpressionEvaluatorFromType: ExpressionEvaluatorFromType | null;
  }) {
    this.getExternalExpressionEvaluatorFromType =
      getExternalExpressionEvaluatorFromType;
  }

  private getComposedExpressionEvaluatorFromType = ({
    type,
  }: {
    type: string;
  }): EvaluatorInterface => {
    const expressionEvaluator =
      getExpressionEvaluatorFromType({ type }) ||
      this.getExternalExpressionEvaluatorFromType?.({ type });
    if (!expressionEvaluator) {
      throw Error(`unknown type ${type} in expression evaluation`);
    }
    return expressionEvaluator;
  };

  getUpdatedVariables({
    variables,
    context,
    variableUpdates,
  }: {
    variables: Variable[];
    context: Context;
    variableUpdates?: VariablesUpdate[];
  }): Variable[] {
    const variableFromId = _.object(_.pluck(variables, 'id'), variables);
    const variableUpdateFromId = _.object(
      _.pluck(variableUpdates || [], 'variableId'),
      variableUpdates || [],
    );

    const visitStatusFromId: VisitStatusFromId = {};
    const newStateFromVariableId: NewStateFromVariableId = {};

    const getNewStateFromVariableQuery = ({
      variableId,
      query,
    }: {
      variableId?: string;
      query?: Record<string, string>;
    }) => {
      if (variableId) {
        return getNewStateFromVariableId({ variableId });
      }
      if (query) {
        const filteredVariables = _.where(variables, query);
        if (_.isEmpty(filteredVariables)) {
          throw Error(NO_VARIABLE_FOUND_ERROR_MESSAGE);
        }
        return getNewStateFromVariableId({
          variableId: filteredVariables[0].id,
        });
      }
      return { value: undefined };
    };

    const getNewStateFromVariableId = ({
      variableId,
    }: {
      variableId: string;
    }): { value: string | undefined } => {
      if (!variableFromId[variableId]) {
        throw Error(NO_VARIABLE_FOUND_ERROR_MESSAGE);
      }
      if (newStateFromVariableId[variableId]) {
        return newStateFromVariableId[variableId];
      }
      if (visitStatusFromId[variableId] === 'visiting') {
        throw Error('cycle dependency');
      }

      visitStatusFromId[variableId] = 'visiting';
      const variable = variableFromId[variableId];

      const getVariableState = (): { value: string | undefined } => {
        const vUpdate = variableUpdateFromId[variableId];
        if (vUpdate) {
          return vUpdate.state;
        }
        if (!variable) {
          return { value: undefined };
        }
        if (variable.state?.isLocked) {
          return variable.state;
        }
        const expressionEvaluator = this.getComposedExpressionEvaluatorFromType(
          {
            type: variable.type,
          },
        );
        return expressionEvaluator.evaluate({
          context,
          expression: variable,
          getExpressionEvaluatorFromType:
            this.getComposedExpressionEvaluatorFromType,
          externalVariableEvaluator: getNewStateFromVariableQuery,
        });
      };

      newStateFromVariableId[variableId] = getVariableState();
      visitStatusFromId[variableId] = 'finished';
      return newStateFromVariableId[variableId];
    };

    _.each(variables, ({ id }) => {
      getNewStateFromVariableId({ variableId: id });
    });

    return _.map(variables, (variable) => ({
      ...variable,
      state: newStateFromVariableId[variable.id],
    }));
  }
}
