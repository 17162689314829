import React, {
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { Button, Checkbox, Popup } from 'semantic-ui-react';
import classNames from 'classnames';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import {
  ArchivedState,
  Segment,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import { Segmentations } from '@/components/PipelineSegmentation/data';
import {
  ARCHIVED_ENGAGEMENT,
  ARCHIVED_REASONS,
  sentryCaptureException,
} from '@/common';
import MoveToStageAction from '@/components/PipelineSegmentation/MoveToStageAction';
import useUpdateProfilesPipelineStage from '@/graphql/hooks/searchPoolJob/useUpdateProfilesMissionPipelineStage';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { useRemoveProfilesFromMission } from '@/graphql/searchPoolProfiles';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import DropdownContainer from '@/components/Reveal/Dropdown/DropdownContainer/DropdownContainer';
import useInterestedSubPipeline from '@/graphql/hooks/searchPoolJob/useInterestedSubPipeline';
import AddToMission from './AddToMission';
import NewMissionItem from '../NewMissionItem';
import MissionDropdownContainer from './MissionDropdownContainer';

import styles from '../NewMissionItem.module.less';
import localStyles from './ProjectsProfileManagement.module.less';

const defaultPipelineId = Segmentations[0].id;
const defaultPipelineState = Segmentations[0].segments[0].id;

interface Props {
  missionsInfo: any;
  missions: any;
  clientId: string;
  missionToDisplay: any;
  setMissionToDisplay: (mission: any) => void;
  shiftSidebar: boolean;
  onChangeStage?: (data: any) => void;
}

type Mission = {
  missionId: string;
  mission: {
    data: {
      title: string;
    };
  };
};

interface MissionSelectorDropdownProps {
  shouldEditMoreMissions: boolean;
  profile: any;
  missions: Mission[];
  handleMoreMissionsStageClick: (mission: Mission) => void;
  getProfileStage: (mission: Mission) => {
    id: string;
    name: string;
  };
  onOpenModal: (event: any, mission: Mission) => void;
  moreMissionsSelectedMission: {
    missionId: string;
    name: string;
    stageId: string;
    segments: Segment[];
  };
  setShouldEditMoreMissions: (value: SetStateAction<boolean>) => void;
  onChangeStage: (
    newStageId: string,
    prevStageId: string,
    labels?: string[],
    clientArchiveReasonId?: string,
  ) => Promise<void>;
  updatePipelineLoading: boolean;
  clientId: string;
}

const MissionSelectorDropdown: FC<MissionSelectorDropdownProps> = ({
  shouldEditMoreMissions,
  setShouldEditMoreMissions,
  profile,
  missions,
  handleMoreMissionsStageClick,
  getProfileStage,
  onOpenModal,
  moreMissionsSelectedMission,
  onChangeStage,
  updatePipelineLoading,
  clientId,
}) => {
  const { t } = useTranslation();

  if (!shouldEditMoreMissions) {
    return (
      <>
        <div className='all-mission-header'>
          {t('profile.missionsAndSequences.allProfileMissions', {
            name: profile?.resumeData?.firstname,
          })}
        </div>
        {_.map(missions, (missionInfo) => (
          <div className='all-missions-item' key={missionInfo.missionId}>
            <div className='mission-name'>
              {missionInfo?.mission?.data?.title}
            </div>
            <div className='mission-stage'>
              <div
                className='mission-item'
                onClick={() => handleMoreMissionsStageClick(missionInfo)}
                aria-hidden='true'
              >
                <div
                  className={classNames(
                    'mission-pipeline-dropdown projectProfileManagment',
                    localStyles.missionStateContainer,
                  )}
                >
                  <span>{getProfileStage(missionInfo)?.name}</span>
                  <div>
                    <i
                      aria-hidden='true'
                      className={classNames(
                        localStyles.dropdownIcon,
                        'dropdown icon',
                      )}
                    />
                    <i
                      aria-hidden='true'
                      className='ri-close-fill ri-lg'
                      onClick={(event) => onOpenModal(event, missionInfo)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <div className='all-mission-header'>
        <i
          className='ri-arrow-left-s-line'
          onClick={() => setShouldEditMoreMissions(false)}
        />
        <span className='contact-name'>{moreMissionsSelectedMission.name}</span>
        {t('profile.missionsAndSequences.allProfileMissions', {
          name: profile?.resumeData?.firstname,
        })}
      </div>
      <div className='move-to-stage-action'>
        <MoveToStageAction
          currentStage={moreMissionsSelectedMission?.stageId}
          stages={moreMissionsSelectedMission?.segments}
          onMove={onChangeStage}
          isSubmitting={updatePipelineLoading}
          candidates={[profile]}
          setOpen={setShouldEditMoreMissions}
          absolutePosition='right'
          clientId={clientId}
        />
      </div>
    </>
  );
};

interface MissionSelectorProps extends MissionSelectorDropdownProps {
  isMinimized: boolean;
  missions: Mission[];
  missionToDisplay: Mission;
  shiftSidebar: boolean;
}

const MissionSelector: FC<MissionSelectorProps> = ({
  isMinimized,
  missionToDisplay,
  missions,
  shouldEditMoreMissions,
  setShouldEditMoreMissions,
  profile,
  handleMoreMissionsStageClick,
  getProfileStage,
  onOpenModal,
  moreMissionsSelectedMission,
  onChangeStage,
  updatePipelineLoading,
  clientId,
  shiftSidebar,
}) => {
  const isPlugin = useIsPlugin();

  if (!missions.length) {
    return null;
  }

  if (isMinimized) {
    return (
      <div
        className={classNames(localStyles.missionName, localStyles.onlyTitle)}
      >
        <span>{missionToDisplay?.mission?.data?.title}</span>
      </div>
    );
  }

  if (missions.length === 1) {
    return (
      <div className={localStyles.missionName}>
        <span>{missionToDisplay?.mission?.data?.title}</span>
      </div>
    );
  }

  let offset = 0;
  if (isPlugin && !shiftSidebar) {
    offset = 200;
  }

  return (
    <Popup
      className={classNames('more-missions-popup', {
        [localStyles.pluginPopup]: isPlugin,
      })}
      trigger={
        <div className={localStyles.missionName}>
          <span>{missionToDisplay?.mission?.data?.title}</span>
          {missions.length > 1 && (
            <div className={localStyles.showMoreMissions}>
              +{missions.length - 1}
              <i className='dropdown icon' />
            </div>
          )}
        </div>
      }
      content={
        <MissionSelectorDropdown
          missions={missions}
          shouldEditMoreMissions={shouldEditMoreMissions}
          profile={profile}
          handleMoreMissionsStageClick={handleMoreMissionsStageClick}
          getProfileStage={getProfileStage}
          onOpenModal={onOpenModal}
          moreMissionsSelectedMission={moreMissionsSelectedMission}
          setShouldEditMoreMissions={setShouldEditMoreMissions}
          onChangeStage={onChangeStage}
          updatePipelineLoading={updatePipelineLoading}
          clientId={clientId}
        />
      }
      basic
      on='click'
      position='bottom right'
      popperModifiers={[
        {
          name: 'offset',
          options: {
            offset: [offset, 0],
          },
        },
        {
          // Fix a positioning problem
          name: 'preventOverflow',
          options: {
            boundariesElement: 'viewport',
          },
        },
      ]}
    />
  );
};

const ProjectsProfileManagement: React.FC<Props> = ({
  missionsInfo,
  missions,
  clientId,
  missionToDisplay,
  setMissionToDisplay,
  shiftSidebar,
  onChangeStage,
}) => {
  const { profile, profileId } = useCandidateViewContext();
  const isPlugin = useIsPlugin();
  const { t } = useTranslation();
  const notification = useNotificationSystem();
  const { user } = useCurrentUser();
  const { isMinimized } = useMinimizedView();

  useEffect(() => {
    if (missionsInfo) {
      setMissionToDisplay(
        [...missionsInfo].sort(
          (a: any, b: any) =>
            Date.parse(b.insertionDate) - Date.parse(a.insertionDate),
        )?.[0],
      );
    }
  }, [missionsInfo, setMissionToDisplay]);

  const missionsSorted = missionsInfo
    ? [...missionsInfo].sort((a: any, b: any) => {
        if (a.mission?.data?.title < b.mission?.data?.title) {
          return -1;
        }
        if (a.mission?.data?.title > b.mission?.data?.title) {
          return 1;
        }
        return 0;
      })
    : [];
  const [removeProfilesFromMission] = useRemoveProfilesFromMission();
  const [updatePipeline, { loading: updatePipelineLoading }] =
    useUpdateProfilesPipelineStage();

  const [shouldEditMoreMissions, setShouldEditMoreMissions] = useState(false);
  const [removeFromMissionModalOpen, setRemoveFromMissionModalOpen] =
    useState(false);
  const [selectedEngagement, setSelectedEngagement] = useState('not-contacted');
  const [selectedReason, setSelectedReason] = useState('');
  const [shouldInterruptSequence, setShouldInterruptSequence] = useState(true);
  const [moreMissionsSelectedMission, setMoreMissionsSelectedMission]: any =
    useState({});
  const { interestedSubPipeline } = useInterestedSubPipeline({
    missionId: missionToDisplay?.missionId,
    queryOptions: {
      skip: !missionToDisplay?.missionId,
    },
  });
  const substepNamesById = useMemo(() => {
    const result = {} as Record<string, string>;
    _.each(interestedSubPipeline?.steps || [], ({ id, title }) => {
      result[id] = title;
    });
    return result;
  }, [interestedSubPipeline]);

  const getProfileStage = useCallback(
    (
      missionInfo,
    ): {
      id: string;
      name: string;
    } => {
      const missionInfoData = missionInfo?.data;
      if (missionInfoData?.archived) {
        return {
          id: ArchivedState.ARCHIVED,
          name: t(`reveal.pipelineSegmentations.archived`),
        };
      }
      const firstSegmentationState = missionInfoData?.segmentationStates?.[0];
      if (
        firstSegmentationState?.state === 'interested' &&
        firstSegmentationState?.interestedStepId
      ) {
        const id = firstSegmentationState.interestedStepId;
        const name = substepNamesById[id];
        return { id, name };
      }
      const id = firstSegmentationState?.state || defaultPipelineState;
      return {
        id,
        name: t(`reveal.pipelineSegmentations.${id}`),
      };
    },
    [t, substepNamesById],
  );

  const { id: stageId, name: stageName } = useMemo(
    () => getProfileStage(missionToDisplay),
    [getProfileStage, missionToDisplay],
  );

  const displayInterruptSequence =
    profile?.currentSequenceInfo?.sequenceId &&
    profile?.currentSequenceInfo?.state !== 'completed';

  const handleMoreMissionsStageClick = (missionInfo: any) => {
    const selectedMission = {
      stageId:
        missionInfo?.data?.segmentationStates?.[0]?.state ||
        defaultPipelineState,
      segments: Segmentations[0].segments,
      missionId: missionInfo?.missionId,
      segmentationId:
        missionsInfo?.data?.segmentationState?.[0]?.segmentationId ||
        defaultPipelineId,
      name: missionInfo?.mission?.data?.title,
      segmentationStates: missionInfo?.data?.segmentationStates,
    };
    setMoreMissionsSelectedMission(selectedMission);
    setShouldEditMoreMissions(true);
  };

  const onOpenModal = (
    event: React.MouseEvent<HTMLElement>,
    missionInfo: any,
  ): void => {
    event.stopPropagation();
    const segmentationStates = missionInfo?.data?.segmentationStates;
    if (!segmentationStates || segmentationStates?.[0].state === 'pending') {
      setRemoveFromMissionModalOpen(false);
      onRemoveFromMission(missionInfo);
      return;
    }
    hidePopup();
    setRemoveFromMissionModalOpen(true);
  };

  const onRemoveFromMission = async (missionInfo: any) => {
    try {
      await removeProfilesFromMission({
        variables: {
          searchPoolId: 'reveal',
          input: {
            missionId: missionInfo?.missionId,
            profileIds: [profileId],
            shouldInterruptSequence: true,
            answerLabels: _.compact([selectedEngagement, selectedReason]),
            ...(shouldInterruptSequence && {
              sequenceId: profile?.currentSequenceInfo?.sequenceId,
            }),
          },
        },
      });
      setRemoveFromMissionModalOpen(false);
      notification.success(
        t('reveal.candidatesView.actionsBox.removeFromMissionSuccess', {
          count: 1,
        }),
      );
    } catch (e) {
      sentryCaptureException({ error: e });
      notification.error(
        t('reveal.candidatesView.actionsBox.removeFromMissionError'),
      );
    }
  };

  const hidePopup = () => {
    document.body.click();
  };

  const innerOnChangeStage = async (
    newStageId: string,
    prevStageId: string,
    labels?: string[],
    clientArchiveReasonId?: string,
    interestedStepId?: string,
  ) => {
    try {
      const { data } = await updatePipeline({
        missionId: moreMissionsSelectedMission?.missionId,
        stage: newStageId,
        profileIds: [profileId],
        segmentationId: moreMissionsSelectedMission?.segmentationId,
        labels,
        clientArchiveReasonId,
        interestedStepId,
      });
      notification.success(
        t('reveal.candidatesView.actionsBox.updateStageSuccess'),
      );
      setShouldEditMoreMissions(false);

      // Allows us to emit an event up towards the plugin
      if (onChangeStage) {
        onChangeStage({
          newData: data,
          prevSegment: prevStageId,
          jobId: moreMissionsSelectedMission?.missionId,
        });
      }
    } catch (e) {
      sentryCaptureException({ error: e });
      console.info(e);
      notification.error(
        t('reveal.candidatesView.actionsBox.updateStageError'),
      );
    }
  };

  return (
    <>
      <div
        className='missions-management-container'
        id='missions-management-container'
      >
        <div className='title'>
          {t('profile.missionsAndSequences.missions')}
        </div>
        <MissionDropdownContainer
          missions={missions}
          user={user}
          profileId={profileId}
        >
          {!_.isEmpty(missionsInfo) ? (
            <div
              className={classNames(
                localStyles.missions,
                localStyles.missionStateContainer,
                {
                  plugin: isPlugin,
                  'with-more-missions': missionsInfo.length > 1,
                },
              )}
            >
              <DropdownContainer
                mini
                className={classNames(localStyles.missionSelector)}
              >
                <MissionSelector
                  shiftSidebar={shiftSidebar}
                  isMinimized={isMinimized}
                  missionToDisplay={missionToDisplay}
                  missions={missionsSorted}
                  shouldEditMoreMissions={shouldEditMoreMissions}
                  profile={profile}
                  handleMoreMissionsStageClick={handleMoreMissionsStageClick}
                  getProfileStage={getProfileStage}
                  onOpenModal={onOpenModal}
                  moreMissionsSelectedMission={moreMissionsSelectedMission}
                  setShouldEditMoreMissions={setShouldEditMoreMissions}
                  onChangeStage={innerOnChangeStage}
                  updatePipelineLoading={updatePipelineLoading}
                  clientId={clientId}
                />
              </DropdownContainer>
              {!isMinimized && (
                <DropdownContainer mini className={localStyles.stageSelector}>
                  <div className='missions-stage'>
                    <NewMissionItem
                      key={missionToDisplay?.missionId}
                      searchPoolId='reveal'
                      stageId={stageId}
                      stageName={stageName}
                      missionId={missionToDisplay?.missionId}
                      profileId={profileId}
                      segmentationId={defaultPipelineId}
                      missionsInfoData={missionToDisplay?.data}
                      clientId={clientId}
                      // please note we are not using innerOnChangeStage
                      // since the update itself happens on NewMissionItem
                      onChangeStage={onChangeStage}
                    />
                  </div>
                </DropdownContainer>
              )}
            </div>
          ) : (
            <div className='empty-mission'>
              {t('profile.missionsAndSequences.notInMission')}
            </div>
          )}
          {(!isMinimized || _.isEmpty(missionsInfo)) && (
            <AddToMission missionsInfo={missionsInfo} />
          )}
        </MissionDropdownContainer>
      </div>
      {removeFromMissionModalOpen && (
        <ConfirmationModal
          open
          onCancel={() => setRemoveFromMissionModalOpen(false)}
          onSubmit={() => onRemoveFromMission(moreMissionsSelectedMission)}
          header={`${t(
            'common.remove',
          )} ${`${profile.resumeData?.firstname} ${profile.resumeData?.lastname}`} ${t(
            'reveal.candidatesView.actionsBox.fromMission',
          )}`}
          content={
            <div>
              <div className={styles.reasonsLabel}>
                <span className={styles.mainTitle}>
                  {t('reveal.candidatesView.actionsBox.reasonsLabel')}
                </span>{' '}
                <span className={styles.subTitle}>
                  {t('common.facultative')}
                </span>
              </div>

              <div className={styles.reasonsButtons}>
                {ARCHIVED_ENGAGEMENT.map((reason) => (
                  <Button
                    key={reason}
                    className={classNames(
                      styles.button,
                      selectedEngagement === reason && styles.buttonSelected,
                    )}
                    size='small'
                    onClick={() => setSelectedEngagement(reason)}
                  >
                    {t(
                      `reveal.candidatesView.actionsBox.missionsReasons.${reason}`,
                    )}
                  </Button>
                ))}
              </div>

              {selectedEngagement === 'profile-interested' && (
                <>
                  <div className={styles.reasonsLabel}>
                    <span className={styles.mainTitle}>
                      {t('common.details')}
                    </span>{' '}
                  </div>
                  <div className={styles.reasonsButtons}>
                    {ARCHIVED_REASONS.map((reason) => (
                      <Button
                        key={reason}
                        className={classNames(
                          styles.button,
                          selectedReason === reason && styles.buttonSelected,
                        )}
                        size='small'
                        onClick={() => setSelectedReason(reason)}
                      >
                        {t(
                          `reveal.candidatesView.actionsBox.missionsReasons.${reason}`,
                        )}
                      </Button>
                    ))}
                  </div>
                </>
              )}

              {displayInterruptSequence && (
                <div className={styles.interruptSequence}>
                  <Checkbox
                    label={
                      <label>
                        <span className={styles.checkboxTitle}>
                          {profile?.currentSequenceInfo?.sequence.title}{' '}
                        </span>
                        <span className={styles.checkboxSubtitle}>
                          {t(
                            'reveal.candidatesView.actionsBox.missionsReasons.willBeStopped',
                          )}
                        </span>
                      </label>
                    }
                    defaultChecked={shouldInterruptSequence}
                    onChange={(event, data) => {
                      if (data?.checked) {
                        setShouldInterruptSequence(data.checked);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          }
          submit={t('common.remove')}
          cancel={t('common.cancel')}
          size='tiny'
        />
      )}
    </>
  );
};

export default ProjectsProfileManagement;
