import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import useSearchPoolMiniProfileById from '@/graphql/hooks/profiles/useSearchPoolMiniProfileById';
import { useHistory } from 'react-router-dom';
import _ from 'underscore';
import { CandidateViewProvider } from '@/context/CandidateView/useCandidateViewContext';
import ProfileRow from './ProfileRow';
import { SkipProfileMessage } from './SkipWithEmailContext/SkipWithEmailContext';

interface Props {
  jobId: string;
  profile: any;
  setSelectedProfileId: (id: string) => void;
  clientId: string;
  currentTab: string;
  searchPoolId: string;
  skipProfile: (
    profileId: string,
    reason?: string,
    message?: SkipProfileMessage,
  ) => void;
  confirmProfile: any;
  removeRecommendedProfile: any;
  isApplicantRow?: boolean;
  skipLoading?: boolean;
}

function useHasQueryParam(param: string, value = '') {
  const { location } = useHistory();
  return Boolean(location?.search?.includes(`${param}=${value}`));
}

const RecommendedProfileRow: React.FC<Props> = ({
  jobId,
  profile,
  setSelectedProfileId,
  clientId,
  currentTab,
  searchPoolId,
  skipProfile,
  confirmProfile,
  removeRecommendedProfile,
  isApplicantRow = false,
  skipLoading,
}) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const [displayAddSequenceModal, setDisplayAddSequenceModal] = useState(false);
  const { profile: miniProfile } = useSearchPoolMiniProfileById({
    searchPoolId,
    profileId: profile?.id,
  });

  const canRemoveRecommendation =
    useHasQueryParam('reco', '1') && user?.isAdmin;

  const profileWithRecommandation = {
    ...profile,
    ...miniProfile,
  };

  const handleSubmit = (selectedSequenceId: string | null) => {
    confirmProfile(profile.id, selectedSequenceId);
    setDisplayAddSequenceModal(false);
  };

  const applicationMission = _.findWhere(
    profileWithRecommandation?.applicationMissions || [],
    { missionId: jobId },
  );
  const applicationMissionSkipReason = _.find(
    applicationMission?.history || [],
    (h) => h.archiveReason?.type === 'skipped',
  )?.archiveReason?.skipReason;

  return (
    <ProfileRow
      key={profile?.id}
      onClick={(): void => setSelectedProfileId(profile?.id)}
    >
      <ProfileRow.ProfileDetails
        clientId={clientId}
        searchPoolId={searchPoolId}
        profile={profileWithRecommandation}
      />
      <ProfileRow.Actions>
        {currentTab === 'active' && isApplicantRow && (
          <CandidateViewProvider profileId={profile.id} clientId={clientId}>
            <ProfileRow.SkipAction
              onSkip={(reason?: string, message?: SkipProfileMessage) =>
                skipProfile(profile.id, reason, message)
              }
              isApplicationAction={isApplicantRow}
              skipLoading={skipLoading}
            />
          </CandidateViewProvider>
        )}
        {currentTab !== 'accepted' && (
          <div className='add-to-sequence-modal-wrapper'>
            {applicationMissionSkipReason && (
              <div className='skip-reason'>
                {t(
                  `reveal.applicantProfiles.skip.reasons.${applicationMissionSkipReason}`,
                )}
              </div>
            )}
            <ProfileRow.Action
              primacy='primary'
              onClick={(e) => {
                e.stopPropagation();
                setDisplayAddSequenceModal(true);
              }}
            >
              <i className='ri-thumb-up-line' />
              {t(
                `reveal.${
                  isApplicantRow ? 'applicantProfiles' : 'recommendedProfiles'
                }.confirm.trigger`,
              )}
            </ProfileRow.Action>
            {displayAddSequenceModal && (
              <ProfileRow.AddToSequenceModal
                jobId={jobId}
                onSubmit={(selectedSequenceId): void =>
                  handleSubmit(selectedSequenceId)
                }
                onClose={(): void => setDisplayAddSequenceModal(false)}
                absolutePositionStyles={{ right: '2px', top: '-96px' }}
              />
            )}
          </div>
        )}
        {currentTab === 'active' && !isApplicantRow && (
          <CandidateViewProvider profileId={profile.id} clientId={clientId}>
            <ProfileRow.SkipAction
              onSkip={(reason?: string) => skipProfile(profile.id, reason)}
              isApplicationAction={isApplicantRow}
              skipLoading={skipLoading}
            />
          </CandidateViewProvider>
        )}
        {canRemoveRecommendation && (
          <ProfileRow.Action
            primacy='secondary'
            onClick={(e) => {
              e.stopPropagation();
              removeRecommendedProfile(profile.id, profile.recommendationId);
            }}
          >
            <i className='ri-trash-line' />
            {t(
              `reveal.missions.mission.${
                isApplicantRow ? 'applications' : 'recommandations'
              }.${
                isApplicantRow
                  ? 'removeApplicationBtn'
                  : 'removeRecommandationBtn'
              }`,
            )}
          </ProfileRow.Action>
        )}
      </ProfileRow.Actions>
    </ProfileRow>
  );
};

export default RecommendedProfileRow;
