import gql from 'graphql-tag';
import Author from './Author';
import TranslatableText from './TranslatableText';
import ContactFlowActionFormat from './ContactFlowActionFormat';

export default gql`
  fragment TimelineItem on TimelineItem {
    author {
      ...Author
    }
    id
    isPinned
    targets {
      targetId
      type
      isMain
      name
    }
    draftStatus
    draftReferenceSharedActivityId
    date
    isExternal
    removalReason
    linkedProfileReference {
      profileId
      title {
        ...TranslatableText
      }
    }
    action {
      type
      ... on CommentAction {
        comment {
          text
        }
      }
      ... on UnipileMessageAction {
        subtype
        unipileChatId
        unipileAccountId
        text
        from
        to
      }
      ... on TextMessageAction {
        text
        from
        to
      }
      ... on LogActivityAction {
        subtype
        customActivityId
        customActivityTitle {
          ...TranslatableText
        }
        comment {
          text
        }
        format {
          ...ContactFlowActionFormatFragment
        }
        labels {
          id
        }
      }
      ... on StepAction {
        step {
          stepId
          details
        }
      }
      ... on MilestoneAction {
        milestoneId
      }
      ... on MessageAction {
        from
        to
        message {
          body
          subject
          labels
        }
        trackOnEmailOpen
        trackOnLinkClick
        events {
          type
          timestamp
          link
        }
      }
      ... on MeetingAction {
        startDate
      }
      ... on ImportAction {
        type
        jobOfferId
      }
      ... on RevealImportAction {
        type
        sourceId
      }
      ... on ApplicationAction {
        type
        jobOfferId
      }
      ... on AddedToMissionAction {
        type
        missionId
      }
      ... on RemovedFromMissionAction {
        type
        missionId
      }
      ... on SkippedFromMissionRecommendationsAction {
        type
        missionId
        reason
      }
      ... on SkippedFromMissionApplicationsAction {
        type
        missionId
        reason
      }
      ... on AcceptedFromMissionRecommendationsAction {
        type
        missionId
      }
      ... on AcceptedFromMissionApplicationsAction {
        type
        missionId
      }
      ... on MissionStageUpdatedAction {
        type
        missionId
        stage
        subStage
        label
        customArchiveReasonId
        customArchiveReasonTitle
      }
      ... on EnrolledInSequenceAction {
        type
        sequenceId
      }
      ... on EnrolledInCampaignAction {
        type
        campaignId
      }
      ... on UnenrolledFromSequenceAction {
        type
        sequenceId
        interruptionReason
      }
      ... on UnenrolledFromCampaignAction {
        type
        campaignId
      }
      ... on TaskCompletedAction {
        type
        subtype
        messageSubtype
        messageSubject
        messageBody
        message {
          body
          labels
        }
        customActionId
        icon {
          url
          emojiText
          type
          id
        }
        title {
          ...TranslatableText
        }
        form {
          fields {
            id
            type
            subtype
            isMultiselect
            options {
              id
              title {
                ...TranslatableText
              }
              selected
            }
            title {
              ...TranslatableText
            }
            value
            onEvents {
              rule {
                type
              }
              effect {
                type
                key
              }
            }
            condition {
              type
              key
              targetValues
              effect {
                type
              }
            }
            required
          }
        }
        helpText {
          ...TranslatableText
        }
      }
      ... on TaskSnoozedAction {
        type
        snoozeDate
        snoozeReasonId
        snoozeReasonTitle
        snoozeComment
      }
      ... on MarkedAsDoNotContactAction {
        type
        reason
      }
      ... on UnmarkedAsDoNotContactAction {
        type
      }
      ... on CampaignMailAction {
        type
        trackOnEmailOpen
        trackOnLinkClick
        campaignId
        events {
          type
          timestamp
          link
        }
      }
    }
    atsSynchronizations {
      connector {
        type
      }
      sourceItemId
    }
  }
  ${Author}
  ${TranslatableText}
  ${ContactFlowActionFormat}
`;
