import {
  ArchivedState,
  PipelineState,
  RecencySegment,
  Segmentation,
} from '@/components/PipelineSegmentation/pipelineSegmentation';

export const Segmentations = [
  {
    id: 'D2KD9DW3',
    translationKey: 'default',
    type: 'pipeline',
    segments: [
      { id: PipelineState.PENDING, name: 'Not Contacted' },
      { id: PipelineState.IN_PROGRESS, name: 'Contacted' },
      { id: PipelineState.REPLIED, name: 'Replied' },
      { id: PipelineState.INTERESTED, name: 'Interested' },
      { id: PipelineState.HIRED, name: 'Hired' },
      { id: ArchivedState.ARCHIVED, name: 'Archived' },
    ],
    showDisplayTypeSelector: true,
  },
  {
    id: 'FESH3IQ7',
    translationKey: 'by-recency-all-contact',
    type: 'free-segmentation',
    segments: [{ id: RecencySegment.ALL, name: 'All contacts' }],
    showDisplayTypeSelector: false,
    displayType: 'table',
  },
  {
    id: 'DPW1JH4Q',
    translationKey: 'by-recency',
    type: 'free-segmentation',
    segments: [
      { id: RecencySegment.RECENT_WEEK, name: 'Added this week' },
      { id: RecencySegment.RECENT_MONTH, name: 'Added this month' },
      { id: RecencySegment.ALL, name: 'All contacts' },
    ],
    showDisplayTypeSelector: false,
    displayType: 'table',
  },
  {
    id: 'SEK4NDS2',
    translationKey: 'by-disponibility',
    type: 'hs-next-availability-date',
    segments: [
      {
        id: 'KHFHT6',
        translationKey: 'today',
        nextAvailabilityFilter: {
          lessThan: '1679525999999',
        },
        type: 'hs-next-availability-date',
      },
      {
        id: 'AOL5G4',
        translationKey: 'oneOrTwoDays',
        nextAvailabilityFilter: {
          greaterThan: '1679526000000',
          lessThan: '1679698799999',
        },
        type: 'hs-next-availability-date',
      },
      {
        id: 'DSLF3J',
        translationKey: 'threeToSevenDays',
        nextAvailabilityFilter: {
          greaterThan: '1679698800000',
          lessThan: '1680040799999',
        },
        type: 'hs-next-availability-date',
      },
      {
        id: 'SEF3SG',
        translationKey: 'eightToFourteenDays',
        nextAvailabilityFilter: {
          greaterThan: '1680040800000',
          lessThan: '1680645599999',
        },
        type: 'hs-next-availability-date',
      },
      {
        id: 'NCOR3D',
        translationKey: 'fifteenToThirtyDays',
        nextAvailabilityFilter: {
          greaterThan: '1680645600000',
          lessThan: '1681941599999',
        },
        type: 'hs-next-availability-date',
      },
      {
        id: 'NJ6L3E',
        translationKey: 'moreThanThirtyDays',
        nextAvailabilityFilter: {
          greaterThan: '1681941600000',
        },
        type: 'hs-next-availability-date',
      },
      {
        id: 'MSV4FN',
        translationKey: 'unknown',
        nextAvailabilityFilter: {
          isUnknown: true,
        },
        type: 'hs-next-availability-date',
      },
    ],
    showDisplayTypeSelector: false,
    displayType: 'kanban',
    needsShowKanbanFeatureFlag: true,
    disableDragAndDrop: true,
    featureFlag: 'show-kanban-availability',
  },
  {
    id: 'SEK4NDS3',
    translationKey: 'by-day',
    type: 'hs-next-availability-date',
    subtype: 'date',
    segments: [
      {
        id: 'KHFHT7',
        translationKey: 'day+0',
        nextAvailabilityFilter: {
          greaterThan: '1679439600000',
          lessThan: '1679525999999',
        },
        type: 'hs-next-availability-date',
        subtype: 'date',
      },
      {
        id: 'AOL5G5',
        translationKey: 'day+1',
        nextAvailabilityFilter: {
          greaterThan: '1679526000000',
          lessThan: '1679612399999',
        },
        type: 'hs-next-availability-date',
        subtype: 'date',
      },
      {
        id: 'DSLF3K',
        translationKey: 'day+2',
        nextAvailabilityFilter: {
          greaterThan: '1679612400000',
          lessThan: '1679698799999',
        },
        type: 'hs-next-availability-date',
        subtype: 'date',
      },
      {
        id: 'SEF3SH',
        translationKey: 'day+3',
        nextAvailabilityFilter: {
          greaterThan: '1679698800000',
          lessThan: '1679785199999',
        },
        type: 'hs-next-availability-date',
        subtype: 'date',
      },
      {
        id: 'NCOR3D',
        translationKey: 'day+4',
        nextAvailabilityFilter: {
          greaterThan: '1679785200000',
          lessThan: '1679871599999',
        },
        type: 'hs-next-availability-date',
        subtype: 'date',
      },
      {
        id: 'NCOR3E',
        translationKey: 'day+5',
        nextAvailabilityFilter: {
          greaterThan: '1679871600000',
          lessThan: '1679957999999',
        },
        type: 'hs-next-availability-date',
        subtype: 'date',
      },
      {
        id: 'NCOR3F',
        translationKey: 'day+6',
        nextAvailabilityFilter: {
          greaterThan: '1679958000000',
          lessThan: '1680044399999',
        },
        type: 'hs-next-availability-date',
        subtype: 'date',
      },
    ],
    showDisplayTypeSelector: false,
    displayType: 'kanban',
    needsShowKanbanFeatureFlag: true,
    disableDragAndDrop: true,
    featureFlag: 'show-week-kanban',
  },
] as const satisfies Segmentation[];

export default Segmentations;
